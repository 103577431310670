import React from 'react';

import { DescriptionContainer, DescriptionText, DescriptionLine, Icon, Text, ImageContainer, Diploma, Statistics, BigText, SmallText, Stat, TitleMobile, TitleDesk, SwiperContainerMobile, StyledPagination, SwiperContainerDesktop, DescriptionContainerSwiper, StatisticsDesktop, StatisticsMobile } from "./description.styled";
import svg1 from '../../../../assets/image/descsvgs/svg1.svg';
import svg2 from '../../../../assets/image/descsvgs/svg2.svg';
import svg3 from '../../../../assets/image/descsvgs/svg3.svg';
import svg4 from '../../../../assets/image/descsvgs/svg4.svg';
import svg5 from '../../../../assets/image/descsvgs/svg5.svg';

const Diplom1 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930137/assets/image/diploms/highRes/iko1y4geeditjqnidjdj.webp'
const Diplom2 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/x76carfnbc3cbycymbny.webp'
const Diplom3 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/n65sdqmmtpedzzfxvwtd.webp'
const Diplom4 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/vjsf2nodwkpr1lziec97.webp'
const Diplom5 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/rwmt2wswjdf9jpfwnh6j.webp'
const Diplom7 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/lkl50f8ozmfmte7xjdwc.webp'
const Diplom9 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/e8u9gbsmeerahvsxhzeg.webp'
const Diplom10 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930137/assets/image/diploms/highRes/az7g7dztdmjwdtmykzov.webp'
const Diplom11 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930137/assets/image/diploms/highRes/ubcs0306yvhunpbrmkvo.webp'
const Diplom12 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/dtewulbgxmdm3p9u5hpt.webp'
const Diplom13 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930138/assets/image/diploms/highRes/rsaljju0wg2yaqombjxz.webp'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination } from 'swiper/modules';

export const Description = ({ lang }) => {
  const swiperParamsMobile = {
    modules: [Navigation, Pagination],
    navigation: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };
  return (
    <DescriptionContainer>
      <TitleMobile>{lang.description.mainTitle}</TitleMobile>
      <DescriptionContainerSwiper>
        <DescriptionText>
          <DescriptionLine>
            <Icon src={svg1} />
            <Text>{lang.description.feature1}</Text>
          </DescriptionLine>
          <DescriptionLine>
            <Icon src={svg2} />
            <Text>{lang.description.feature2}</Text>
          </DescriptionLine>
          <DescriptionLine>
            <Icon src={svg3} />
            <Text>{lang.description.feature3}</Text>
          </DescriptionLine>
          <DescriptionLine>
            <Icon src={svg4} />
            <Text>{lang.description.feature4}</Text>
          </DescriptionLine>
          <DescriptionLine>
            <Icon src={svg5} />
            <Text>{lang.description.feature5}</Text>
          </DescriptionLine>
        </DescriptionText>
        <SwiperContainerDesktop className="outer">
          <Swiper {...swiperParamsMobile}>
            <SwiperContainerDesktop>
              <SwiperSlide key={1}>
                <Diploma src={Diplom1} />
              </SwiperSlide>
              <SwiperSlide key={2}>
                <Diploma src={Diplom2} />
              </SwiperSlide>
              <SwiperSlide key={3}>
                <Diploma src={Diplom3} />
              </SwiperSlide>
              <SwiperSlide key={4}>
                <Diploma src={Diplom4} />
              </SwiperSlide>
              <SwiperSlide key={5}>
                <Diploma src={Diplom5} />
              </SwiperSlide>
              <SwiperSlide key={7}>
                <Diploma src={Diplom7} />
              </SwiperSlide>
              <SwiperSlide key={9}>
                <Diploma src={Diplom9} />
              </SwiperSlide>
              <SwiperSlide key={10}>
                <Diploma src={Diplom10} />
              </SwiperSlide>
              <SwiperSlide key={11}>
                <Diploma src={Diplom11} />
              </SwiperSlide>
              <SwiperSlide key={12}>
                <Diploma src={Diplom12} />
              </SwiperSlide>
              <SwiperSlide key={13}>
                <Diploma src={Diplom13} />
              </SwiperSlide>
            </SwiperContainerDesktop>
            <StyledPagination>
              <div className="swiper-pagination"></div>
            </StyledPagination>
          </Swiper>
        </SwiperContainerDesktop>
      </DescriptionContainerSwiper>
      <SwiperContainerMobile className="outer">
        <Swiper {...swiperParamsMobile}>
          <SwiperContainerMobile>
            <SwiperSlide key={1}>
              <Diploma src={Diplom1} />
            </SwiperSlide>
            <SwiperSlide key={2}>
              <Diploma src={Diplom2} />
            </SwiperSlide>
            <SwiperSlide key={3}>
              <Diploma src={Diplom3} />
            </SwiperSlide>
            <SwiperSlide key={4}>
              <Diploma src={Diplom4} />
            </SwiperSlide>
            <SwiperSlide key={5}>
              <Diploma src={Diplom5} />
            </SwiperSlide>
            <SwiperSlide key={7}>
              <Diploma src={Diplom7} />
            </SwiperSlide>
            <SwiperSlide key={9}>
              <Diploma src={Diplom9} />
            </SwiperSlide>
            <SwiperSlide key={10}>
              <Diploma src={Diplom10} />
            </SwiperSlide>
            <SwiperSlide key={11}>
              <Diploma src={Diplom11} />
            </SwiperSlide>
            <SwiperSlide key={12}>
              <Diploma src={Diplom12} />
            </SwiperSlide>
            <SwiperSlide key={13}>
              <Diploma src={Diplom13} />
            </SwiperSlide>
          </SwiperContainerMobile>
          <StyledPagination>
            <div className="swiper-pagination"></div>
          </StyledPagination>
        </Swiper>
      </SwiperContainerMobile>
      <TitleDesk>{lang.description.mainTitle}</TitleDesk>
      <StatisticsMobile className="Mobile">
        <Stat>
          <BigText>{lang.description.title1.number}</BigText>
          <SmallText>{lang.description.title1.text.replace('|', '')}</SmallText>
        </Stat>
        <Stat>
          <BigText>{lang.description.title2.number}</BigText>
          <SmallText>{lang.description.title2.text.replace('|', '')}</SmallText>
        </Stat>
        <Stat>
          <BigText>{lang.description.title3.number}</BigText>
          <SmallText className="line-break-text">
            {lang.description.title3.text.replace('|', '')}
          </SmallText>
        </Stat>
        <Stat>
          <BigText>{lang.description.title4.number}</BigText>
          <SmallText>{lang.description.title4.text.replace('|', '')}</SmallText>
        </Stat>
      </StatisticsMobile>
      <StatisticsDesktop className="Desktop">
        <Stat>
          <BigText>{lang.description.title1.number}</BigText>
          <SmallText>
            {' '}
            {lang.description.title1.text.split('|').map((text, index, array) => (
              <React.Fragment key={index}>
                {text}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}
          </SmallText>
        </Stat>
        <Stat>
          <BigText>{lang.description.title2.number}</BigText>
          <SmallText>
            {' '}
            {lang.description.title2.text.split('|').map((text, index, array) => (
              <React.Fragment key={index}>
                {text}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}
          </SmallText>
        </Stat>
        <Stat>
          <BigText>{lang.description.title3.number}</BigText>
          <SmallText className="line-break-text">
            {' '}
            {lang.description.title3.text.split('|').map((text, index, array) => (
              <React.Fragment key={index}>
                {text}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}
          </SmallText>
        </Stat>
        <Stat>
          <BigText>{lang.description.title4.number}</BigText>
          <SmallText>
            {' '}
            {lang.description.title4.text.split('|').map((text, index, array) => (
              <React.Fragment key={index}>
                {text}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}
          </SmallText>
        </Stat>
      </StatisticsDesktop>
    </DescriptionContainer>
  );
};