import styled, { css } from "styled-components";
import { COLORS } from "../../../theme";
import '../../../../style.css'

import { DesktopBr, MobileBr } from '../services/services.styled';

export const ProblemsContainer = styled('div')`
  @media (min-width: 720px) {
    max-width: 1420px;
    margin: 80px auto;
    padding: 0 70px;
  }
`;

export const ProblemsTitle = styled('div')`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  padding: 0 62px;
  @media (min-width: 720px) {
    margin-bottom: 50px;
    padding: 0;
    text-align: start;
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.023%;
    margin-left: 36px;
  }
  @media (max-width: 400px) {
    width: 100%;
    padding: 0
  }
`;

export const ImageTextContainer = styled('div')`
  position: absolute;
  top: 8px;
  left: 10px;
  width: 117px;
  text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.8);
  line-height: 98.523%;

  @media (min-width: 720px) {
    height: 64px;
    left: 20px;
    top: 15px;
    width: 225px;
  }
`;

export const ImageText = styled('div')`
  color: #fff;
  font-family: LatoOriginal;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 98.523%;
  width: ${props => props.width === 'ru' ? '130px' : '117px'};
  @media (min-width: 720px) {
    width: 100%;
    font-size: 20px;
    max-width: fit-content;
    line-height: 98.523%;
  }
  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

export const ImageTextColored = styled('div')`
  color: #2aa8c3;
  font-family: LatoOriginal;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 98.523%;
  letter-spacing: 0.39px;
  @media (min-width: 720px) {
    font-size: 20px;
    line-height: 98.523%;
  }
`;

export const CardsContainerMobile = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  gap: 16px;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const CardsContainerDesktop = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  gap: 16px;

  @media (min-width: 720px) {
    gap: 80px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const Card = styled('div')`
  position: relative;
  max-width: 328px;
  max-height: 434px;
  padding: 12px 17px;
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  width: 45%;
  z-index: 9996 !important;
  &:nth-last-child(1) {
    width: 100%;
    box-sizing: content-box;
  }
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(42, 168, 195, 0.35);

  @media (min-width: 720px) {
    &:nth-last-child(1) {
      box-sizing: border-box;
      heigth: 100%;
    }

    padding: 27px 21px 32px 31px;
  }
`;

export const PhotoContainer = styled('div')`
  position: relative;
  width: ${(props) => (props.width === 'fullWidth' ? '100%' : '138px')};
  height: 127.397px;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  border-radius: 10px;
  background-size: cover;
  -webkit-box-shadow: inset 0px 57px 123px -32px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 57px 123px -32px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 57px 123px -32px rgba(0, 0, 0, 0.75);

  @media screen and (min-width: 720px) {
    position: relative;
    width: 265px;
    height: 231px;
    background-image: url(${(props) => props.bgImage});
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
    -webkit-box-shadow: inset 0px 57px 123px -32px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 57px 123px -32px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 57px 123px -32px rgba(0, 0, 0, 0.75);
  }
  @media (max-width: 400px) {
    width: ${(props) => (props.width === 'fullWidth' ? '100%' : '124px')};
    height: 127.397px;
  }
`;

export const Photo = styled('div')`
  margin-bottom: 8px;
  border-radius: 5px;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 50px 50px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 128px;
  @media screen and (min-width: 720px) {
    border-radius: 10px;
    width: 265px;
    height: 231px;
  }
  &.hide-on-mobile {
    @media screen and (min-width: 720px) {
      display: none;
    }
  }

  &.hide-on-desk {
    @media screen and (max-width: 720px) {
      display: none;
    }
  }
`;

export const CardsTitle = styled('div')`
  text-transform: uppercase;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.3px;
  margin-top: 8px;

  @media (min-width: 720px) {
    text-align: start;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 1.1px;
    margin-top: 17px;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
`;

export const RequestButton = styled('button')`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${COLORS.Morskoi};
  box-shadow: 0px 3px 7px 0px rgba(42, 168, 195, 0.49);
  color: white;
  font-family: Inter;
  font-size: 20px;
  font-weight: 300;
  margin-top: 16px;
  padding: 14px;
  transition: all 0.5s;
  cursor: pointer;
  width: 240px;

  @media (max-width: 720px) {
    display: none;
  }

  &:hover {
    filter: brightness(110%);
    box-shadow: 0px 9px 21px 0px rgba(42, 168, 195, 0.49);
  }
`;