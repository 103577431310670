import styled from "styled-components";

import { COLORS } from "../../../theme";

export const Container = styled('div')`
  @media screen and (min-width: 720px) {
    padding: 0 70px;
    padding-top: 50px;
  }
`;

export const HotlineContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin: 50px 0;

  @media screen and (min-width: 720px) {
    flex-direction: row;
    border-radius: 20px;
    box-shadow: 0px 4px 25px 0px rgba(42, 168, 195, 0.35);
    height: 234px;
    padding: 72px;
    gap: 25px;
    max-width: 1146px;
    margin: 70px auto;
  }
`;

export const SuccessWindow = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  position: absolute;
  left: 14%;
  top: 50px;
  border-radius: 10px;
  border: 1px solid ${COLORS.Morskoi};

  @media (min-width: 720px) {
    width: 300px;
    height: 50px;
    left: 37%;
    top: -70px;
  }
`;

export const HotlineTitle = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-weight: 800;
`;

export const RequestContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  width: 100%;

  @media screen and (min-width: 720px) {
    margin-top: 35px;
    flex-direction: row;
    margin: 0;
    width: inherit;
  }
`;

export const RequestText = styled('div')`
  margin-left: 44px;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
`;

export const RequestCard = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 32px 20px;

  @media screen and (min-width: 720px) {
    box-shadow: none;
  }
`;

export const Input = styled('input')`
  all: unset;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 8px;

  ::placeholder {
    color: #aaa7a7;
    font-size: 12px;
  }

  @media screen and (min-width: 720px) {
    width: 300px;
    margin-right: 70px;
    margin-bottom: 24px;
    margin-top: 24px;

    ::placeholder {
      font-family: Inter;
      font-size: 20px;
      font-weight: 300;
      line-height: normal;
    }
  }

  &:hover {
    ::placeholder {
      color: #000;
    }
  }
`;

export const RequestButton = styled('button')`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  background: ${COLORS.Morskoi};
  box-shadow: 0px 3px 7px 0px rgba(42, 168, 195, 0.49);
  color: white;
  font-family: Inter;
  font-size: 20px;
  font-weight: 300;
  margin: 36px 0;
  cursor: pointer;
  transition: all 0.5s;

  @media screen and (min-width: 720px) {
    width: 265px;
    margin: 85px 0 0;
  }

  &:hover {
    filter: brightness(110%);
    box-shadow: 0px 9px 21px 0px rgba(42, 168, 195, 0.49);
  }
`;

export const Form = styled('form')`
  width: 100%;
`