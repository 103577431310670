import styled from "styled-components";
import { COLORS } from "../../../theme";
import { css } from 'styled-components';

export const ReviewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: -1;

  @media (min-width: 720px) {
    margin-top: 200px;
  }
`;

export const ReviewTitle = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  line-height: 135.023%;
  margin-bottom: 35px;

  @media (min-width: 720px) {
    width: 100%;
    max-width: 1420px;
    padding-left: 140px;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.023%;
  }
`;

export const ColorContainer = styled('div')`
  width: 100%;
  background-color: ${COLORS.Morskoi};
`;

export const ReviewCardContainer = styled('div')`
  display: flex;
  height: 440px;
  width: 100%;
  background-color: ${COLORS.Morskoi};
  margin: 0 auto;

  @media (min-width: 720px) {
    display: none
  }
`;

export const ReviewCardContainerDesktop = styled('div')`
  display: flex;
  height: 440px;
  max-width: 1420px;
  background-color: ${COLORS.Morskoi};
  position: relative;
  margin: 0 auto;

  @media (max-width: 720px) {
    display: none;
  }

  .swiper {
    position: unset;
    max-width: 900px;
  }
`;

export const ReviewCardInner = styled('div')`
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: white;
  height: 100%;
  border-radius: 10px;
  padding: 16px 16px;
  border: 2px solid #2aa8c3;
`;

export const ReviewCardOuter = styled('div')`
  margin: 45px auto;
  padding: 14px 16px;
  height: 80%;
  max-width: 222px;
  background-color: #dfdbdb;
  border-radius: 20px;
  box-shadow: 0px 6px 8px 3px #117389;
`;

export const ReviewCardOuterDesktop = styled('div')`
  margin-top: 40px;
  padding: 14px 16px;
  height: 80%;
  background-color: #dfdbdb;
  border-radius: 20px;
  box-shadow: 0px 6px 8px 3px #117389;
  transition: all 0.3s;

  @media (min-width: 720px) {
    margin-top: 40px;
    padding: 14px 16px;
    height: 80%;
    background-color: #dfdbdb;
    border-radius: 20px;
    box-shadow: 0px 6px 8px 3px #117389;
    margin: 45px auto;
    max-width: 222px;
  }

  .swiper-slide-active & {
    background-color: white;
    height: 90%;
    margin-top: 20px;
  }
`;


export const Comment = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 140%;
`;

export const CommentTitle = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 10px;
`;

export const NavigationContainer = styled.div`
  width: 1100px;
  position: absolute;
  top: 50%;
  left: 12%;
  z-index: 9999 !important;

  .swiper-button-prev {
    left: -20px !important;
    z-index: 9999 !important;
  }
  .swiper-button-next {
    right: -20px !important;
    z-index: 9999 !important;
  }
`;