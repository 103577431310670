import styled from "styled-components";

export const VideoContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 36px 0;
  margin-bottom: 16px;
  @media (min-width: 720px) {
    margin: 120px 0;
  }
`;

export const IFrame = styled('iframe')`
  display: block;
  width: 1024px;
  height: 253px;

  @media (min-width: 720px) {
    height: 576px;
  }
`;