import styled from "styled-components";

export const RecommendationsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

export const RecTitleContainer = styled('div')`
  width: 100%;
  text-align: center;
`;

export const RecommendationsTitle = styled('div')`
  color: #000;
  text-align: center;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;

  @media (min-width: 1320px) {
    align-self: center;
    margin-bottom: 0;
    width: 1180px;
    text-align: start;
    font-size: 32px;
  }
`;

export const CardContainer = styled('div')`
  padding: 16px;
  border: 1px solid #04758E;
  border-radius: 20px;
  position: relative;
  width: 304px;
  height: 340px;
  overflow: visible;
  margin: 0 auto;
  box-shadow: 0px 4px 6px 1px #11738974;

  @media (min-width: 1318px) {
    width: 1217px;
    display: flex;
    flex-direction: row;
    border: none;
    box-shadow: 0px 0px 0px 0px white;
    align-items: row;
    gap: 48px;
    height: 480px;
  }

  @media (max-width: 400px) {
    width: 70vw;
  }
`;

export const Card = styled('div')`
  padding: 55px;
  width: 100vw;

  @media (min-width: 1318px) {
    align-items: center;
    padding: 0;
    margin-left: 25px;
  }
`;

export const CardDesktop = styled('img')`
  display: none;

  @media (min-width: 1318px) {
    background: cover;
    display: flex;
    width: 1170px;
    height: 370px;
  }
`;

export const SwiperContainer = styled('div')`
  display: flex;
  align-items: center;
  align-self: center;
  width: 100vw;

  @media (min-width: 819px) and (max-width: 1318px) {
    display: none;
  }
`;

export const CardPhoto = styled('div')`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: absolute;
  top: -12%;
  left: -12%;
  z-index: 1;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-size: ${(props) => props.backgroundSize};
  background-position: ${(props) => props.backgroundPosition};
  background-repeat: no-repeat;

  @media (min-width: 1318px) {
    display: none;
  }
  @media (max-width: 400px) {
    left: -20%;
  }

  filter: grayscale(100%);
`;

export const CardTitle = styled('div')`
  color: #04758E;
  text-align: center;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 15px;

  @media (min-width: 1318px) {
    display: none;
  }
`;

export const CardText = styled('div')`
  color: #000;
  font-family: 'LatoOriginal';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 14px;
  margin-bottom: 14px;

  @media (min-width: 1318px) {
    display: none;
  }
`;

export const SwiperMediumContainer = styled('div')`
  display: none;

  @media (min-width: 820px) and (max-width: 1317px) {
    display: flex;
    align-items: center;
    height: 600px;
  }
`;

export const NavigationContainer = styled.div`
  width: 100vw;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 9999 !important;

  .swiper-button-prev {
    width: 22px !important;
    height: 22px !important;
    background-size: 22px !important;
    left: 10px !important;
    z-index: 9999 !important;
    @media (min-width: 720px) {
      top: -30px !important;
      width: 50px !important;
      height: 50px !important;
      background-size: 50px !important;
    }
  }
  .swiper-button-next {
    width: 22px !important;
    height: 22px !important;
    background-size: 22px !important;
    right: 10px !important;
    z-index: 9999 !important;
    @media (min-width: 720px) {
      top: -30px !important;
      width: 50px !important;
      height: 50px !important;
      background-size: 50px !important;
    }
  }
`;