import { useState } from "react";
import { sendEmail } from '../../utils/func/sendEmail';

import { FooterContainer, ContactContainer, GlobalContactContainer, ContactTitle, ContactInput, ContactButton, ContactWithUsContainer, ContactWithUsText, ContactWithUsTitle, ReturnToFrontPage,PrivacyPolicyContainer, PrivacyPolicy, SuccessWindow, Image, PrivacyPolicyContainerDesktop, PrivacyPolicyContainerMobile, Logo } from "./footer.styled";
import returnButton from './returnbutton.svg'

export const Footer = ({ lang, toggleModal }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [requestSent, setRequestSent] = useState(false);

  if (requestSent) {
    setTimeout(() => {
      setRequestSent(false);
    }, 3000);
  }

  const navigate = (link) => {
    location.href = link
  }

  return (
    <form onSubmit={(e) => sendEmail(e, phone, name, setName, setPhone, toggleModal)}>
      <FooterContainer>
        <GlobalContactContainer>
          <ContactContainer>
            <ContactTitle>{lang.footer.contacts}</ContactTitle>
            <ContactInput
              autoFocus={false}
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={lang.input.name}
            />
            <ContactInput
              autoFocus={false}
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={lang.input.phone}
            />
            <ContactButton>{lang.buttons.send}</ContactButton>
          </ContactContainer>
          <ContactWithUsContainer>
            <ContactWithUsTitle>{lang.footer.title}</ContactWithUsTitle>
            <ContactWithUsText href="tel:+380676394855">{lang.footer.contactTel}</ContactWithUsText>
            <ContactWithUsText>{lang.footer.skype}</ContactWithUsText>
            <ContactWithUsText>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {lang.links.write}
                <Logo
                  style={{marginLeft: '18px'}}
                  onClick={() => navigate(lang.links.whatsapp)}
                  src="https://static.wixstatic.com/media/f34a49_b1f52fbbfd9d4ad582d72ace381c6eea~mv2.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Massenger%20icons%20white-07.png"
                />
                <Logo
                  onClick={() => navigate(lang.links.telegram)}
                  src="https://static.wixstatic.com/media/f34a49_bbc17ea7a3ce4357949893df716b7dbd~mv2.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Massenger%20icons%20white-06.png"
                />
              </div>
            </ContactWithUsText>
            <div style={{ height: '4px' }}></div>
            <ContactWithUsText>{lang.footer.address}</ContactWithUsText>
            <ContactWithUsText>{lang.footer.city}</ContactWithUsText>
          </ContactWithUsContainer>
        </GlobalContactContainer>
        <ReturnToFrontPage onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <Image src={returnButton} />
        </ReturnToFrontPage>
        <PrivacyPolicyContainerDesktop>
          <PrivacyPolicy>
            {lang.footer.confidential} {lang.footer.confidential2}
          </PrivacyPolicy>
          <PrivacyPolicy>{lang.footer.policy}</PrivacyPolicy>
        </PrivacyPolicyContainerDesktop>
        <PrivacyPolicyContainerMobile>
          <PrivacyPolicy>{lang.footer.confidential}</PrivacyPolicy>
          <PrivacyPolicy>{lang.footer.confidential2}</PrivacyPolicy>
          <PrivacyPolicy>{lang.footer.policy}</PrivacyPolicy>
        </PrivacyPolicyContainerMobile>
      </FooterContainer>
    </form>
  );
}
