import { MyHistoryContainer, SecondContainer, MyHistoryPhoto, MyHistoryText, DesktopTextContainer, MyHistoryTitle, TextContainer, Anchor, GalleryContainer, TitleContainer, DesktopTitle, Photo_1, Photo_2, Photo_3, Photo_4, Photo_5, Photo_6, Photo_7, Photo_8, GalleryShadow } from "./myHistory.styled";

const mainPhoto =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930140/assets/image/yqjtj9gl3qfrxatqcjgf.webp';
const photo1 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930139/assets/image/myHistory/tdqgstk9no2kqhinbmau.webp'
const photo2 = 'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930139/assets/image/myHistory/x1xdwflnlb30h8gwnmej.webp'
const photo3 =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930139/assets/image/myHistory/czuulzayoxhsccympez4.webp';
const photo4 =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930139/assets/image/myHistory/fe3jvsvxh4at3tsovqms.webp';
const photo5 =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930144/assets/image/myHistory/x6zjhtmnw7265mvgakk3.webp';
const photo6 =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930139/assets/image/myHistory/okd1vgievqgkbg0wqpoc.webp';
const photo7 =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930139/assets/image/myHistory/xrx0gsljn4c9yjitvdrp.webp';
const photo8 =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930139/assets/image/myHistory/vsb6s0xjdxqeu3dxrglj.webp';
import { useState } from "react";

export const MyHistory = ({ lang }) => {
  const [openText, setOpenText] = useState(false);

  const handleOpenText = () => {
    setOpenText(!openText);
    window.scrollTo({
      top: 6050,
      behavior: 'smooth'
    });
  };

  return (
    <MyHistoryContainer>
      <SecondContainer>
        <TitleContainer>
          <MyHistoryTitle>{lang.history.title}</MyHistoryTitle>
          <MyHistoryPhoto src={mainPhoto} />
          <TextContainer>
            <DesktopTitle>{lang.history.title}</DesktopTitle>
            <MyHistoryText>
              {lang.history.text1}
              {!openText && (
                <Anchor onClick={() => setOpenText(!openText)}> {lang.readNext.open} </Anchor>
              )}
              {openText && (
                <>
                  <MyHistoryText>
                    <br />
                    {lang.history.text2}
                  </MyHistoryText>
                  <br />
                  <MyHistoryText>{lang.history.text3}</MyHistoryText>
                  <br />
                  <MyHistoryText>{lang.history.text4}</MyHistoryText>
                  <Anchor onClick={handleOpenText}> {lang.readNext.close} </Anchor>
                </>
              )}
            </MyHistoryText>
          </TextContainer>
        </TitleContainer>
        <DesktopTextContainer>
          <MyHistoryText>{lang.history.text2}</MyHistoryText>
          <MyHistoryText>{lang.history.text3}</MyHistoryText>
          <MyHistoryText>{lang.history.text4}</MyHistoryText>
        </DesktopTextContainer>
      </SecondContainer>
      <GalleryContainer>
        <GalleryShadow></GalleryShadow>
        <Photo_1 src={photo1} />
        <Photo_2 src={photo2} />
        <Photo_3 src={photo3} />
        <Photo_4 src={photo4} />
        <Photo_5 src={photo5} />
        <Photo_6 src={photo6} />
        <Photo_7 src={photo7} />
        <Photo_8 src={photo8} />
      </GalleryContainer>
    </MyHistoryContainer>
  );
}

