const irina =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930140/assets/image/gcxabtmzlxkjmzpqgweo.webp';
const ganna =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930135/assets/image/ieiraminbuj6rsgni8ts.webp';
const marianna =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930144/assets/image/z8yhss6m1f3yvoumibqe.webp';
const sergey =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930144/assets/image/afradvrhobep86jwozfe.webp';
const petr =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930140/assets/image/rcoqgrxwx2vaytscjcqn.webp';
const irinaDesktop =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930142/assets/image/rec/xttmen1ggtr185vojnv4.webp';
const annaDesktop =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930142/assets/image/rec/vwyjuq71jw2rsgtm3ogv.webp';
const mariannaDesktop =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930142/assets/image/rec/u7c8qfak5dqx5e4vvsxw.webp';
const sergeyDesktop =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930142/assets/image/rec/lipco0nmxoqeqy6azz41.webp';
const petrDesktop =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930142/assets/image/rec/jy8tuxd0hjhhpocfhmzq.webp';

export const data = [
  {
    id: 3,
    picture: marianna,
    desktopRecommendation: irinaDesktop,
    title: 'марианна лапина рекомендует:',
    firstContent: 'Хорошо работает с запросами *взаимоотношений, возрастными кризисами*',
    secondContent:
      'Алексей, как человек, ответственный и эмпатийный, готов прийти на помощь. *Для него ценность - длительные отношения.* Целеустремленный и ас в коммуникации.',
    thirdContent: 'Как психолог - *ответственен, эмпатийный, понимающий*',
    backgroundSize: '150%',
    backgroundPosition: '40% 10%'
  },
  {
    id: 5,
    picture: petr,
    desktopRecommendation: mariannaDesktop,
    title: 'Петр Литвяк рекомендует:',
    firstContent:
      'Хорошо работает с запросами *Расставания и потери, Сложности в отношениях, Личностное развитие.*',
    secondContent: 'Алексей, как человек, *отзывчивый друг, легкий на контакт.*',
    thirdContent: 'Как психолог - *знающий, поддерживающий и мудрый*'
  },
  {
    id: 1,
    picture: irina,
    desktopRecommendation: petrDesktop,
    title: 'Ірина Паніна рекомендує:',
    firstContent:
      'Добре працює із запитами: самотність, стосунки в парі, проживання втрати та розставання.',
    secondContent:
      'Олексій, як людина, *дуже чуйний*. Він добре *відчуває, що відбувається між клієнтом та терапевтом* і це допомагає вести клієнта у потрібному напрямку. *Особистий досвід* та знання, чутливість, допомагають створювати поле довіри та безпеку.',
    thirdContent:
      'Як психолог - його досвід, дає *добрі результати* у роботі з клієнтами. Олексій має *високий рівень професійної підготовки*, уміння знаходити підхід до будь-якої людини.',
    backgroundSize: '110%',
    backgroundPosition: '40% 10%'
  },
  {
    id: 4,
    picture: sergey,
    desktopRecommendation: sergeyDesktop,
    title: 'Сергей Соломаха рекомендует:',
    firstContent:
      'Хорошо работает с запросами *эмоциональных расстройств, внутренних конфликтов, возрастных кризисов.* Чуткий, поддерживающий терапевт.',
    secondContent: 'Алексей, как человек, *внимательный, сопереживающий, тактичный*',
    thirdContent:
      'Как психолог - профессионал, *знающий свое дело, заботящийся о клиенте*, целевым образом ориентированный на долгосрочный результат, понимающий клиента и его запрос, оснащённый большим количеством психотехник, но при этом человечный',
    backgroundSize: '230%',
    backgroundPosition: '45% 10%'
  },
  {
    id: 2,
    picture: ganna,
    desktopRecommendation: annaDesktop,
    title: 'Ганна чепІкова рекомендуЄ:',
    firstContent:
      "Добре працює із запитами: *стосунки чоловік-жінка, дитячо-батьківські стосунки, депресивні стани, кар'єра.*",
    secondContent: 'Олексій, як людина, *відповідальний, чесний, уважний та дбайливий.*',
    thirdContent:
      'Як психолог – *має сертифікації та академічну освіту, професійно та відповідально виконує свою роботу;* підтримуючий, уважний до деталей; працює на результат; допомагає побудувати власні кордони.',
    backgroundSize: '110%',
    backgroundPosition: '20% 40%'
  }
];


