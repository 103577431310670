
import React from 'react';
import { Element, animateScroll as scroll, Link } from 'react-scroll';

import {
  ProblemsContainer,
  ProblemsTitle,
  CardsContainer,
  Card,
  Photo,
  CardsTitle,
  CardWithMediaQuery,
  PhotoContainer,
  RequestButton,
  ImageTextContainer,
  ImageTextColored,
  ImageText,
  CardsContainerMobile,
  CardsContainerDesktop
} from './problems.styled';
import { DesktopBr, MobileBr } from '../services/services.styled';
import photo1 from '../../../../assets/image/problems/1.png';
import photo2 from '../../../../assets/image/problems/2.png';
import photo3 from '../../../../assets/image/problems/3.png';
import photo4 from '../../../../assets/image/problems/4.png';
import photo5 from '../../../../assets/image/problems/5.png';
import photo6 from '../../../../assets/image/problems/6.png';
import photo7 from '../../../../assets/image/problems/7.png';
import photo8 from '../../../../assets/image/problems/8.png';
import photo9 from '../../../../assets/image/problems/9.png';
import photo10 from '../../../../assets/image/stopwarDesktop.png';
import styled from 'styled-components';

export const Problems = ({ lang }) => {
  const TitleMobile = styled.div`
    @media (min-width: 720px) {
      display: none
    }
  `
  const TitleDesktop= styled.div`
    @media (max-width: 720px) {
      display: none;
    }
  `;
  return (
    <>
      {lang.header.name[0] === 'О' ? (
        <ProblemsContainer>
          <ProblemsTitle>
            <TitleMobile>
              {' '}
              {lang.problems.title.split('|').map((text, index, array) => (
                <React.Fragment key={index}>
                  {text}
                  {index < array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </TitleMobile>
            <TitleDesktop>Проблеми, які ми можемо з вами опрацювати</TitleDesktop>
          </ProblemsTitle>
          <CardsContainerMobile>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo1}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>
                      гуляти, триматися <MobileBr /> за руки
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  СІМЕЙНІ <MobileBr /> ВІДНОСИНИ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo9}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>
                      регулярний
                      <MobileBr /> та із задоволенням
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ЧОЛОВІК <MobileBr /> І ЖІНКА
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo2}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>
                      зберігати гроші <DesktopBr /> у кишенях, <MobileBr /> а не в голові
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  УСПІХ, <MobileBr /> КАРʼЄРА, ГРОШІ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo4}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>хотіти та могти</ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>ОСОБИСТЕ ЗРОСТАННЯ</CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo6}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>
                      знайти баланс між <MobileBr /> хочу і треба, між <MobileBr /> активністю
                      <MobileBr /> та спокоєм
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ВІДНОСИНИ <MobileBr /> З БАТЬКАМИ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo8}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>
                      всі були щасливі, <MobileBr />
                      що знайшли одне одного
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ПРИЙОМНІ ДІТИ <MobileBr /> ТА УСИНОВЛЮВАЧІ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo5}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>радіти по понеділках</ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ВИГОРЯННЯ
                  <MobileBr /> IT-ФАХІВЦІВ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo7}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>біль став легшим</ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ГОРЕ, ТРАВМИ <MobileBr /> І ВТРАТИ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500} offset={-100}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer width="fullWidth" bgImage={photo3}>
                  <ImageTextContainer>
                    <ImageTextColored>щоб...</ImageTextColored>
                    <ImageText>
                      прийняти те, що
                      <MobileBr /> найвіть війна - <MobileBr /> це частина життя
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>КРИЗОВИЙ СУПРОВІД</CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
          </CardsContainerMobile>
          <CardsContainerDesktop>
            <Card>
              <PhotoContainer bgImage={photo1}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>
                    гуляти, тримаючись <DesktopBr /> за руки
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                СІМЕЙНІ <DesktopBr /> ВІДНОСИНИ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo9}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>
                    регулярний <DesktopBr /> та із задоволенням
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ЧОЛОВІК <DesktopBr /> І ЖІНКА
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo2}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>
                    зберігати гроші <DesktopBr /> у кишенях, а не в голові
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                УСПІХ, <DesktopBr /> КАРʼЄРА, ГРОШІ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo6}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>
                    знайти баланс між <DesktopBr /> хочу і треба, між <DesktopBr /> активністю та
                    спокоєм
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ВІДНОСИНИ <DesktopBr /> З БАТЬКАМИ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo8}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>
                    всі були щасливі, що <DesktopBr /> знайшли одне одного
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ПРИЙОМНІ ДІТИ <DesktopBr /> ТА УСИНОВЛЮВАЧІ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo4}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>хотіти та могти</ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>ОСОБИСТЕ ЗРОСТАННЯ</CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo5}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>
                    радіти <DesktopBr /> по понеділках
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ВИГОРЯННЯ
                <DesktopBr /> IT-ФАХІВЦІВ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo7}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>біль став легшим</ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ГОРЕ, ТРАВМИ <DesktopBr /> І ВТРАТИ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo10}>
                <ImageTextContainer>
                  <ImageTextColored>щоб...</ImageTextColored>
                  <ImageText>
                    прийняти те,
                    <DesktopBr /> що найвіть війна - <DesktopBr />
                    це частина життя
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>КРИЗОВИЙ СУПРОВІД</CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
          </CardsContainerDesktop>
        </ProblemsContainer>
      ) : (
        <ProblemsContainer>
          <ProblemsTitle>
            <TitleMobile>
              {' '}
              {lang.problems.title.split('|').map((text, index, array) => (
                <React.Fragment key={index}>
                  {text}
                  {index < array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </TitleMobile>
            <TitleDesktop>Проблемы, которые мы можем с вами проработать</TitleDesktop>
          </ProblemsTitle>
          <CardsContainerMobile>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo1}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>
                      гулять, держась <MobileBr /> за руки
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  СЕМЕЙНЫЕ <DesktopBr /> ОТНОШЕНИЯ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo9}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>
                      регулярный <MobileBr /> и с удовольствием
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  МУЖЧИНА И <MobileBr /> ЖЕНЩИНА
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>

            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo2}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>
                      хранить деньги <MobileBr /> в карманах, <MobileBr /> а не в голове
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  УСПЕХ,
                  <MobileBr /> КАРЬЕРА, ДЕНЬГИ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo4}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>хотеть и мочь</ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  САМОРАЗВИТИЕ И <MobileBr />
                  ЛИЧНОСТНЫЙ РОСТ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>

            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo6}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText width="ru">
                      найти баланс между <MobileBr /> хочу и надо, между <MobileBr /> активностью
                      <MobileBr />и спокойствием
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ОТНОШЕНИЯ <MobileBr /> С РОДИТЕЛЯМИ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo8}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>
                      все были счастливы, <MobileBr /> что нашли друг друга
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ПРИЕМНЫЕ ДЕТИ <MobileBr /> И УСЫНОВИТЕЛИ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo5}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>
                      радоваться <MobileBr /> по понедельникам
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ВЫГОРАНИЕ <MobileBr /> IT-СПЕЦИАЛИСТОВ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer bgImage={photo7}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>боль стала легче</ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  ГОРЕ, ТРАВМЫ <MobileBr /> И ПОТЕРИ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
            <Card>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <PhotoContainer width="fullWidth" bgImage={photo3}>
                  <ImageTextContainer>
                    <ImageTextColored>чтобы...</ImageTextColored>
                    <ImageText>
                      принять то, что
                      <MobileBr /> даже война - <MobileBr /> это часть жизни
                    </ImageText>
                  </ImageTextContainer>
                </PhotoContainer>
                <CardsTitle>
                  КРИЗИСНОЕ <MobileBr /> СОПРОВОЖДЕНИЕ
                </CardsTitle>
                <Link to="invite" smooth={true} duration={500}>
                  <RequestButton>{lang.buttons.request}</RequestButton>
                </Link>
              </Link>
            </Card>
          </CardsContainerMobile>
          <CardsContainerDesktop>
            <Card>
              <PhotoContainer bgImage={photo1}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>
                    гулять, держась <DesktopBr /> за руки
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                СЕМЕЙНЫЕ <DesktopBr /> ОТНОШЕНИЯ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo9}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>
                    регулярный <DesktopBr /> и с удовольствием
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                МУЖЧИНА <DesktopBr /> И ЖЕНЩИНА
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo2}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>
                    хранить деньги <DesktopBr /> в карманах, а не в голове
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                УСПЕХ,
                <DesktopBr /> КАРЬЕРА, ДЕНЬГИ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo6}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>
                    найти баланс между <DesktopBr /> хочу и надо, между <DesktopBr /> активностью и{' '}
                    <DesktopBr />
                    спокойствием
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ОТНОШЕНИЯ <DesktopBr />С РОДИТЕЛЯМИ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo8}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>
                    все были счастливы, <DesktopBr /> что нашли друг друга
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ПРИЕМНЫЕ ДЕТИ <DesktopBr /> И УСЫНОВИТЕЛИ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo4}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>хотеть и мочь</ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                САМОРАЗВИТИЕ И <DesktopBr /> ЛИЧНОСТНЫЙ РОСТ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo5}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>
                    радоваться <DesktopBr /> по понедельникам
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ВЫГОРАНИЕ <DesktopBr /> IT-СПЕЦИАЛИСТОВ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo7}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>боль стала легче</ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                ГОРЕ, ТРАВМЫ <DesktopBr /> И ПОТЕРИ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
            <Card>
              <PhotoContainer bgImage={photo10}>
                <ImageTextContainer>
                  <ImageTextColored>чтобы...</ImageTextColored>
                  <ImageText>
                    принять то, <DesktopBr /> что даже война - <DesktopBr /> это часть жизни
                  </ImageText>
                </ImageTextContainer>
              </PhotoContainer>
              <CardsTitle>
                КРИЗИСНОЕ <DesktopBr /> СОПРОВОЖДЕНИЕ
              </CardsTitle>
              <Link to="invite" smooth={true} duration={500} offset={-100}>
                <RequestButton>{lang.buttons.request}</RequestButton>
              </Link>
            </Card>
          </CardsContainerDesktop>
        </ProblemsContainer>
      )}
    </>
  );
};
