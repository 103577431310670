import emailjs from 'emailjs-com';

export const sendEmail = (e, phone, name, setName, setPhone, toggleModal) => {
  e.preventDefault();

  const serviceID = 'service_69px5tb';
  const templateID = 'template_aey1sfn';
  const userID = 'gzV4E_mYPDomgoKx_';

  const templateParams = {
    name,
    phone
  };

  emailjs
    .send(serviceID, templateID, templateParams, userID)
    .then((response) => {
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });
    setName('');
    setPhone('');
    toggleModal();
};
