import styled from "styled-components";

export const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999 !important;
`
export const MainContainer = styled.div`
  position: fixed;
  top: 35%;
  left: 35%;
  width: 417px;
  height: 291px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 5px rgba(42, 168, 195, 0.25);
  z-index: 9999 !important;

  @media (max-width: 720px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 377px;
    height: 291px;
  }
  @media (max-width: 400px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 291px;
  }
`;

export const Text = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Button = styled.div`
  background: #2aa8c3;
  width: 195px;
  height: 39px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  padding: 10px;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;