import styled from "styled-components";
import { COLORS } from "../../../theme";

export const SelectContainer = styled('select')`
  width: 100px;
  height: 50px;
  border: 1px solid ${COLORS.Morskoi};
  border-radius: 30px;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const StyledOption = styled('option')`
  @media (max-width: 720px) {
    background-color: ${COLORS.Morskoi}};
  }
  border: 1px solid ${COLORS.Morskoi};
  border-radius: 30px;
`;

export const SelectContainerMobile = styled('select')`
  width: 100px;
  height: 50px;
  border: 1px solid ${COLORS.white};
  color: white;
  border-radius: 30px;
  background-color: ${COLORS.Morskoi};

  @media (min-width: 720px) {
    display: none
  }
`;
