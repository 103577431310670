export const cards = [
  {
    id: 1,
    title: 'Віта, рекомендує: ',
    content:
      'Мені сподобалася робота з психологом, ми пройшли тільки 1 сеанс, але я отримала достатньо практичних порад і техніку як справлятися зі своїми проблемами.'
  },
  {
    id: 4,
    title: 'Инна, рекомендует: ',
    content:
      'Работали около 3-х месяцев про взаимоотношения детей - родителей и депрессивное состояние моего ребенка. Вместо неуверенной в себе, депрессивной девушки - вижу свою дочь более уверенной, жизнерадостной. Прошла боязнь общения с мужчинами.'
  },
  {
    id: 2,
    title: 'Юлія, рекомендує: ',
    content:
      'Прийшла на терапію зі своїм хлопцем вирішити питання у парі та порозумітися з партнером. На терапії ми навчилися вчасно промовляти свої емоції та переживання. Олексій емпатійний та співчутливий фахівець.'
  },
  {
    id: 5,
    title: 'Максим, рекомендует: ',
    content:
      'Обращался с проблемой семейных отношений с отцом и отсутствием мотивации. Алексей помог мне найти себя, обрести мотивацию и понять, почему я одинок, наладить отношения с отцом. Он очень понимающий, попадает именно в точку, отзывчивый и знает свое дело.'
  },
  {
    id: 3,
    title: 'Марина, рекомендує: ',
    content:
      'Звернулася з проблемою чоловічо-жіночих стосунків, з темою заперечення, з темою. І з Олексієм, як із психологом, я зрозуміла, що мої проблеми можна розвязати. Олексію, дякую тобі за дбайливість і уважність.'
  },
  {
    id: 6,
    title: 'Иван, рекомендует: ',
    content:
      'Не мог определиться с учёбой. Продолжать или забросить. И как сказать родителям. Поработав с Алексеем, я достиг уверенности в себе, осознанности и научился работать со страхом. Добрый, терпеливый и располагающий к себе специалист. Чувствует, что происходит с клиентом.'
  },
  {
    id: 10,
    title: 'Ірина, рекомендує: ',
    content:
      'Мені було страшно йди вперше і говорити про свої переживання. Говорили про конфлікт між підлітками та батьками. Олексій відразу дав зрозуміти, що страх тут зовсім не доречний. Переосмислила багато моментів і зрозуміла першопричини того, що відбувається.'
  },
  {
    id: 7,
    title: 'Анна, рекомендует: ',
    content:
      'Проблема переезда и адаптации во время войны. Хоть была одна часовая консультация, но Алексей помог определить основные направления, на которых следует сосредоточиться. Открытый, честный, умеет выслушать и отделить зерна от плевел.'
  },
  {
    id: 11,
    title: 'Дарина, рекомендує: ',
    content:
      'Якоїсь миті я повністю втратила себе як особистість. Я стала стриманішою, врівноваженою, мої рішення стали усвідомлені та не імпульсивні. Я нарешті почала любити себе. Олексій, безумовно, професіонал своєї справи.'
  },
  {
    id: 8,
    title: 'Оля, рекомендует: ',
    content:
      'Обратилась с темой “Кризиса в семейных отношениях, постоянные ссоры с мужем”. Я стала более уверенна в себе - я Вселенная :). Где-то по другому посмотрела на проблемы в отношениях. Алексей очень приятный в общении, с первого сеанса расположил к себе. Сеансы были комфортными.'
  },
  {
    id: 9,
    title: 'Вера, рекомендует: ',
    content:
      'Благодарна Алексею за совместно прожитый опыт, за мастерство. Открыла в работе с Алексеем возможность самостоятельно, по новому взглянуть на то, что казалось мне проблемой. Понравилась ненавязчивая поддержка Алексея, участие.'
  },
];
