import { Icon, ServicesContainer, ServicesTitle, CardContainer, CardImage, CardTitle, CardDuration, CardPrice, Consult, ConsultTitle, ConsultText, CardInfo, Card, CardImageContainer, MobileBr, DesktopBr } from "./services.styled";
import photo1 from '../../../../assets/image/circles.svg';
import photo2 from '../../../../assets/image/comment.svg';
import photo3 from '../../../../assets/image/stooliki.png';
import dollar from '../../../../assets/image/dollar.svg';
import time from '../../../../assets/image/time.svg';

export const Services = ({ lang }) => {
  return (
    <ServicesContainer>
      <ServicesTitle>{lang.services.title}</ServicesTitle>
      <CardContainer>
        <Card>
          <CardImageContainer>
            <CardImage src={photo1} />
          </CardImageContainer>
          <CardTitle>{lang.services.cards.card1.title}</CardTitle>
          <CardInfo>
            <CardDuration>
              <Icon src={time} />
              {lang.services.cards.card1.time}
            </CardDuration>
            <CardPrice>
              <Icon src={dollar} />
              {lang.services.cards.card1.price}
            </CardPrice>
          </CardInfo>
        </Card>
        <Card>
          <CardImageContainer>
            <CardImage src={photo2} />
          </CardImageContainer>
          <CardTitle>
            {lang.services.cards.card2.title} <DesktopBr /> {lang.services.cards.card2.title2}
          </CardTitle>
          <CardInfo>
            <CardDuration>
              <Icon src={time} />
              {lang.services.cards.card2.time}
            </CardDuration>
            <CardPrice>
              <Icon src={dollar} />
              {lang.services.cards.card2.price}
            </CardPrice>
          </CardInfo>
        </Card>
        <Card>
          <CardImageContainer>
            <CardImage src={photo3} />
          </CardImageContainer>
          <CardTitle>
            {lang.services.cards.card3.title} <DesktopBr /> {lang.services.cards.card3.title2}
          </CardTitle>
          <CardInfo>
            <CardDuration>
              <Icon src={time} />
              {lang.services.cards.card3.time}
            </CardDuration>
            <CardPrice>
              <Icon src={dollar} />
              {lang.services.cards.card3.price}
            </CardPrice>
          </CardInfo>
        </Card>
      </CardContainer>
      <Consult>
        <ConsultTitle>{lang.services.consult.title}</ConsultTitle>
        <ConsultText>
          {lang.services.consult.text1}
          <MobileBr />
          {lang.services.consult.text2}
          <MobileBr />
          <DesktopBr />
          {lang.services.consult.text3}
        </ConsultText>
      </Consult>
    </ServicesContainer>
  );
};