import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '../../style.css';
import axios from 'axios';
import { MainRouter } from '../navigation';
import { Spinner, SpinnerContainer } from './app.styled';
import { ruLangLocal } from '../../language/ru';
import { ukrLangLocal } from '../../language/ukr';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      cacheTime: Infinity
    }
  }
});

const AppContainer = () => {
  const [language, setLanguage] = useState(ukrLangLocal);
  const [showModal, setShowModal] = useState(false);

  const handleLanguageChange = (lang) => {
    if (lang === 'ruLang') {
      setLanguage(ruLangLocal);
    }
    if (lang === 'ukrLang') {
      setLanguage(ukrLangLocal);
    }
  };
  
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <MainRouter
        toggleModal={toggleModal}
        handleLanguageChange={handleLanguageChange}
        language={language}
        showModal={showModal}
        ukrLang={ukrLangLocal}
        ruLang={ruLangLocal}
      />
    </QueryClientProvider>
  );
};

export default AppContainer;