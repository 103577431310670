import styled from "styled-components";

export const ServicesContainer = styled('div')`
  width: 100%;
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 720px) {
    padding: 0 70px;
    max-width: 1420px;
    margin: auto;
    margin-top: 90px;
  }
`;

export const ServicesTitle = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  line-height: 135%;
  margin-bottom: 16px;

  @media (min-width: 720px) {
    width: 100%;
    font-size: 32px;
    margin: 72px 0 72px 72px;
  }
`;

export const CardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 28px;

  @media (min-width: 720px) {
    padding: 0;
    justify-content: center;
    gap: 30px;
    flex-direction: row;
  }
`;

export const Card = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background: #fff;
  height: 160px;
  box-shadow: 0px 4px 25px 0px rgba(42, 168, 195, 0.35);
  margin-bottom: 48px;
  margin-top: 36px;

  @media (min-width: 720px) {
    padding: 0 20px;
    margin-bottom: 30px;
    width: 362px;
    height: 310px;
  }
`;


export const CardImageContainer = styled('div')`
  position: absolute;
  top: -30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #04758e;
  fill: #04758e;
  filter: drop-shadow(0px 4px 25px rgba(42, 168, 195, 0.35));

  @media (min-width: 720px) {
    height: 130px;
    width: 130px;
    top: -20%;
  }
`;

export const CardImage = styled('img')`
  height: 100%;
  max-width: 100%;
`;

export const CardTitle = styled('div')`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 13.236px;
  font-style: normal;
  font-weight: 700;
  line-height: 130.523%; /* 17.276px */
  letter-spacing: 1.125px;
  text-transform: uppercase;
  margin-top: 25px;

  @media (min-width: 720px) {
    width: 100%;
    margin-top: 50px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130.523%;
    letter-spacing: 2.04px;
    text-transform: uppercase;
  }
`;

export const CardDuration = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @media (min-width: 720px) {
    font-size: 20px;
  }
`;

export const CardPrice = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  gap: 10px;

  @media (min-width: 720px) {
    margin-top: 28px;
    font-size: 20px;
  }
`;

export const Icon = styled('img')`

`

export const MobileBr = styled('br')`
  @media (min-width: 720px) {
    display: none;
  }
`

export const DesktopBr = styled('br')`
  @media (max-width: 720px) {
    display: none;
  }
`;


export const Consult = styled('div')`
  width: calc(100% - 48px);
  border-radius: 20px;
  background: #dfdbdb;
  box-shadow: 0px 4px 25px 0px rgba(42, 168, 195, 0.35);
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  height: 117px;
  margin: 0 24px 95px;
  padding: 20px 40px 0 40px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 720px) {
    width: 1146px;
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
    height: 104px;
    padding: 16px 100px 21px 73px;
  }

  @media (max-width: 400px) {
    padding: 20px 0;
  }
`;

export const CardInfo = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 16px;

  @media (min-width: 720px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ConsultTitle = styled('div')`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 130.523%;
  letter-spacing: 1.105px;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (min-width: 720px) {
    margin-bottom: 0;
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130.523%; /* 31.325px */
    letter-spacing: 2.04px;
    text-transform: uppercase;
    text-align: start;
    width: 40%;
    margin-top: 4px;
  }
`;

export const ConsultText = styled('div')`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 143.023%;
  @media (min-width: 720px) {
    margin-left: 86px;
    width: 100%;
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 143.023%;
    text-align: start;
  }
`;