import { useState } from 'react';

import { sendEmail } from '../../utils/func/sendEmail';
import {
  HotlineContainer,
  HotlineTitle,
  RequestCard,
  RequestButton,
  Input,
  RequestContainer,
  Container,
  SuccessWindow,
  Form
} from './hotline.styled';


export const Hotline = ({ lang, toggleModal }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [requestSent, setRequestSent] = useState(false);

  if (requestSent) {
    setRequestSent(false);
  }
  return (
    <Container>
      <HotlineContainer>
        <HotlineTitle>{lang.hotline.title}</HotlineTitle>
        <form
        style={{width:'100%'}}
          onSubmit={(e) =>
            sendEmail(e, phone, name, setName, setPhone, toggleModal)
          }
        >
          <RequestContainer>
            <RequestCard>
              <Input
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={lang.input.name}
              />
              <Input
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder={lang.input.phone}
              />
            </RequestCard>
            <RequestButton>{lang.buttons.callMe}</RequestButton>
          </RequestContainer>
        </form>
      </HotlineContainer>
    </Container>
  );
}
