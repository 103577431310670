import React from 'react';
import { IFrame, VideoContainer } from './video.styled';

export const YoutubeEmbed = () => (
  <VideoContainer>
    <IFrame
      src={`https://www.youtube.com/embed/mTwN7R_DV3Q`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      loading="lazy"
    />
  </VideoContainer>
);