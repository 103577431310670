import React, { useEffect, useState } from 'react';
import { ruLangLocal } from '../../../../language/ru';
import { ukrLangLocal } from '../../../../language/ukr';
import { SelectContainer, SelectContainerMobile, StyledOption } from './langSelect.styled';

export const LangSelect = ({ selectedLanguage, onChangeLanguage }) => {
  const selectedOption = selectedLanguage === ruLangLocal ? 'ruLang' : 'ukrLang';
  console.log('selectedOption', selectedOption)

  const handleOptionChange = (event) => {
    const selectedLanguage = event.target.value;
    console.log('selectedLanguage', selectedLanguage);
    const newLanguage = selectedLanguage === 'ruLang' ? 'ruLang' : 'ukrLang';
    console.log('newLanguage', newLanguage);
    onChangeLanguage(newLanguage);
  };

  return (
    <>
      <SelectContainer value={selectedOption} onChange={handleOptionChange}>
        <StyledOption value="ukrLang">UKR</StyledOption>
        <StyledOption value="ruLang">RU</StyledOption>
      </SelectContainer>
    </>
  );
};

export const LangSelectMobile = ({ selectedLanguage, onChangeLanguage }) => {
  const selectedOption = selectedLanguage === ruLangLocal ? 'ruLang' : 'ukrLang';
  console.log('selectedOption', selectedOption);

  const handleOptionChange = (event) => {
    const selectedLanguage = event.target.value;
    console.log('selectedLanguage', selectedLanguage);
    const newLanguage = selectedLanguage === 'ruLang' ? 'ruLang' : 'ukrLang';
    console.log('newLanguage', newLanguage);
    onChangeLanguage(newLanguage);
  };

  return (
    <>
      <SelectContainerMobile value={selectedOption} onChange={handleOptionChange}>
        <StyledOption value="ukrLang">UKR</StyledOption>
        <StyledOption value="ruLang">RU</StyledOption>
      </SelectContainerMobile>
    </>
  );
};
