import styled from "styled-components";
import { COLORS } from "../../../theme";

export const FooterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #04758E;
`;

export const GlobalContactContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 980px) {
    flex-direction: row-reverse;
    gap: 100px;
  }
`;

export const ContactContainer = styled('div')`
  margin: 60px;
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 980px) {
    gap: 24px;
  }

  @media (max-width: 400px) {
    margin-bottom: 0;
  }
`;

export const ContactTitle = styled('div')`
  color: #fff;
  font-family: 'Inter', sans-serif;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;

  @media (min-width: 980px) {
    font-size: 24px;
    font-weight: 400;
    width: 500px;
  }

  @media (max-width: 400px) {
    width: 320px;
  }
`;

export const ContactInput = styled('input')`
  all: unset;
  font-family: 'Inter';
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s;

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-family: Inter;
    font-size: 12.422px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  :hover {
    ::placeholder {
      color: white;
    }
  }

  @media (min-width: 980px) {
    font-size: 20px;
    ::placeholder {
      font-size: 20px;
    }
  }

  @media (max-width: 400px) {
    width: 280px;
    margin: 4px auto;
  }
`;

export const ContactButton = styled('button')`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 35px;
  background: ${COLORS.white};
  box-shadow: 0px 3px 7px 0px rgba(42, 168, 195, 0.49);
  color: #04758e;
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 300;
  margin-top: 14px;
  margin-left: 32px;
  margin-right: 32px;
  transition: all 0.5s;
  cursor: pointer;

  :hover {
    box-shadow: 0px 10px 20px 0px rgba(42, 168, 195, 0.5);
    filter: brightness(80%);
  }

  @media (min-width: 980px) {
    align-self: center;
    width: 362px;
    height: 45px;
    font-size: 20px;
  }

  @media (max-width: 400px) {
    width: 224px;
    margin:  24px auto;
  }
`;

export const ContactWithUsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px;
  margin-bottom: 40px;

  @media (min-width: 980px) {
  }
`;

export const ContactWithUsTitle = styled('div')`
  text-align: center;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  margin-bottom: 20px;
  margin-top: 26px;

  @media (min-width: 980px) {
    text-align: start;
    font-size: 32px;
    font-weight: 800;
  }
`;

export const ContactWithUsText = styled('a')`
  color: #fff;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;

  @media (min-width: 980px) {
    font-size: 24px;
  }

  @media (max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ReturnToFrontPage = styled('div')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-self: center;
  margin-bottom: 40px;
  cursor: pointer;

  @media (min-width: 980px) {
    width: 60px;
    height: 60px;
  }
`;

export const PrivacyPolicyContainerDesktop = styled('div')`
  margin-top: 20px;
  margin-left: -30px;
  margin-bottom: 60px;
  width: 100%;

  @media (min-width: 980px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    width: 100vw;
    max-width: 1420px;
    padding: 0 70px;
    height: 50px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 32px;
  height: 32px;
  transform: scale(2);
  margin-left: 16px;
`;

export const PrivacyPolicyContainerMobile = styled('div')`
  margin-top: 20px;
  margin-left: 60px;
  margin-bottom: 60px;
  width: 100%;

  @media (min-width: 980px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    width: 100vw;
    max-width: 1420px;
    padding: 0 70px;
    height: 50px;
  }
  @media (min-width: 720px) {
    display: none;
  }
`;

export const Image = styled('img')`
  width: 32px;
  height: 32px;

  @media (min-width: 720px) {
    width: 100px;
    height: 100px;
  }
`;

export const PrivacyPolicy = styled('div')`
  color: #fff;
  font-family: 'Inter';
  font-size: 8px;
  font-weight: 300;
  width: 100%;

  @media (min-width: 980px) {
    font-size: 14px;
    font-style: 400;
  }
`;

export const SuccessWindow = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 30px;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  position: absolute;
  left: 26.5%;
  top: 320px;
  border-radius: 10px;
  border: 1px solid ${COLORS.white};
  color: white;

  @media (min-width: 720px) {
    left: 67.2%;
    top: 400px;
    width: 300px;
    height: 50px;
  }
`;
