import styled from "styled-components";
import { COLORS } from "../../../theme";

export const InviteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const Form = styled('form')`
  width: calc(100% - 76px);
  margin: 0 auto;
`

export const InviteContainerDesktop = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 70px;
  margin-top: 50px;
  max-width: 1420px;
  margin: 0 auto;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const SuccessWindow = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 30px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  position: absolute;
  left: 14%;
  top: 47%;
  border-radius: 10px;
  border: 1px solid ${COLORS.Morskoi};

  @media (min-width: 720px) {
    width: 300px;
    height: 50px;
    left: 40%;
    top: 200px;
  }
`;


export const InviteTitle = styled('div')`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 135.023%; /* 43.207px */
  text-transform: uppercase;
  margin-bottom: 24px;

  @media (min-width: 720px) {
    color: #04758e;
    text-align: center;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.023%; /* 40.507px */
    text-transform: uppercase;
  }
`;

export const FatText = styled('div')`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 135.023%; /* 43.207px */
  text-transform: uppercase;
  margin-bottom: 24px;

  @media (min-width: 720px) {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const ThinText = styled('div')`
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 135.023%;

  @media (min-width: 720px) {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #04758e;
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 25px 0px rgba(42, 168, 195, 0.35);
  padding: 40px 32px;

  @media (min-width: 720px) {
    flex-direction: column;
    background-color: white;
    box-shadow: none;
  }
`;

export const TextContainerDesktop = styled('div')`
  @media (min-width: 720px) {
    display: flex;
    flex-direction: row;
  }
`;

export const ContactContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: 44px;

  @media (min-width: 720px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const ContactText = styled('div')`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 720px) {
    display: flex;
    flex-direction: column;
    text-align: start;
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 189.523%;
  }
`;

export const ContactLine = styled('div')`
  width: 650px;
`

export const RequestText = styled('div')`
  margin-left: 44px;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RequestCard = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  padding: 32px 20px;

  @media (min-width: 720px) {
    box-shadow: none;
    gap: 60px;
  }
`;

export const Input = styled('input')`
  all: unset;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 8px;

  :hover {
    ::placeholder {
      color: black;
    }
  }

  ::placeholder {
    color: #aaa7a7;
    font-size: 12px;
  }

  @media (min-width: 720px) {
    margin: 0;
    ::placeholder {
      font-size: 20px;
    }
    width: 423px;
    margin-left: 100px;
  }
`;

export const RequestButton = styled('button')`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 12px;
  background: ${COLORS.Morskoi};
  box-shadow: 0px 3px 7px 0px rgba(42, 168, 195, 0.49);
  color: white;
  font-family: Inter;
  font-size: 20px;
  font-weight: 300;
  margin-top: 24px;
  cursor: pointer;
  transition: all 0.5s;

  @media (min-width: 720px) {
    width: 362px;
    padding: 14px 0px;
    margin: 38px auto 0;
  }

  &:hover {
    filter: brightness(110%);
    box-shadow: 0px 9px 21px 0px rgba(42, 168, 195, 0.49);
  }
`;