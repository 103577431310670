import styled from 'styled-components';
import { COLORS } from '../../../theme';

const stool =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930143/assets/image/jk4c859yhzczcvuxics6.webp';
const stooldesktop =
  'https://res.cloudinary.com/dkyzmpg3n/image/upload/v1698930143/assets/image/qmlsuaavsmevdxyqaq03.webp';
export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-image: url(${stool});
  background-position: 100% 95%;
  background-repeat: no-repeat;
  overflow-x: hidden;
  position: relative;
  max-width: 1440px;
  margin: auto;
  padding-bottom: 20px;

  @media (min-width: 720px) {
    background-position: right;
    background-repeat: no-repeat;
    padding: 0 120px;
    padding-bottom: 200px;
    background-image: none;
    background-size: 50%;
  }

  @media (min-height: 1000px) {
    height: 80vh;
    background-size: 55%;
  }

  @media (min-height: 1220px) {
    height: 70vh;
  }
`;

export const ResponsiveBR = styled('br')`
  @media (min-width: 720px) {
    display: none;
  }
`

export const WhiteTextContainer = styled('div')`
  position: absolute;
  right: 170px;
  bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 9997 !important;

  @media (max-width: 720px) {
    display: none;
  }

  @media (min-height: 810px) {
    bottom: 73px;
    right: 5%;
  }

  @media ((min-height: 661px) and (max-height: 760px)) {
    right: 11%;
    bottom: 100px;
  }

  @media ((min-height: 761px) and (max-height: 810px)) {
    right: 10%;
    bottom: 100px;
  }
`;

export const WhiteTextFat = styled('div')`
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const WhiteTextThin = styled('div')`
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Background = styled('div')`
  @media (min-width: 720px) {
    position: absolute;
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 0 120px;
    height: 100%;
    width: 52%;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-image: url(${stooldesktop});
    background-size: 100%;
    z-index: 9997 !important;
    right: 4%;
    bottom: 0;

    @media (min-width: 1080px) {
      background-size: 100%;
      right: 0;
    }

    @media (min-height: 1080px) {
      width: 57%;
    }

    @media ((min-height: 1px) and (max-height: 660px)) {
      width: 40%;
      right: 10%;
      bottom: 120px;
    }

    @media ((min-height: 661px) and (max-height: 760px)) {
      width: 45%;
      right: 5%;
      bottom: 80px;
    }
  }
`;

export const Shape = styled('div')`
  position: absolute;
  width: 160%;
  height: 100%;
  background-color: ${COLORS.Morskoi};
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, -49%);
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
  overflow-x: hidden;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const Offer = styled('div')`
  position: relative;
  width: 650px;
  height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(42, 168, 195, 0.35);
  padding: 45px 100px;
  margin-top: 180px;
  padding-bottom: 20px;
  @media (max-width: 720px) {
    display: none;
  }

  @media (max-height: 750px) {
    margin-top: 150px;
  }

  @media (max-height: 720px) {
    margin-top: 120px;
  }

  @media (max-height: 700px) {
    margin-top: 100px;
  }

`;

export const Header = styled('div')`
  background-color: ${COLORS.Morskoi};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  padding: 30px 28px;
  align-items: center;
  justify-content: space-between;
  z-index: 9997 !important;
  @media (min-width: 720px) {
    background-color: white;
    width: 100vw;
    justify-content: center;
    gap: 50px;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 50px;
    color: black;
    z-index: 1000;
  }
`;

export const HeaderText = styled('div')`
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  text-transform: uppercase;
  font-style: normal;
  line-height: normal;
  color: white;

  @media (min-width: 720px) {
    margin-left: 70px;
    color: black;
  }
`;

export const HeaderName = styled('div')`
  display: flex;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  width: 20px;
`;

export const HeaderProfess = styled('div')`
  font-family: 'Inter';
  font-weight: 300;
  font-size: 9px;
  padding-top: 4px;
`;

export const HeaderPhone = styled('a')`
  font-family: 'Inter';
  color: white;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;

  @media (min-width: 720px) {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.54px;

    &:hover {
      color: ${COLORS.Morskoi};
    }
  }
`;

export const HeaderLinks = styled('div')`
  display: flex;
  gap: 16px;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const HeaderLink = styled('div')`
  &:hover {
    font-weight: 600;
  }

  transition: all 0.5s;
  cursor: pointer;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const MainText = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: 720px) {
    width: 500px;
    margin-bottom: 40px;
  }
`;

export const TopText = styled('div')`
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 46px;
  text-transform: uppercase;
  margin-bottom: 4px;
  @media (min-width: 720px) {
    padding: 0 80px;
    line-height: 60px;
    font-size: 48px;
    color: ${COLORS.Morskoi};
  }
`;
export const BottomText = styled('div')`
  line-height: 30px;
`;

export const ThinText = styled('div')`
width: 100%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.9px;

  @media (min-width: 720px) {
    color: black;
    font-size: 24px;
    margin-bottom: 16px;
  }
`;

export const FatText = styled('div')`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;

  @media (min-width: 720px) {
    font-size: 48px;
    font-weight: 800;
    color: ${COLORS.Morskoi};
  }
`;

export const RequestContainer = styled('div')`
  flex-direction: column;
  margin-top: 70px;
  padding-bottom: 30px;

  @media (min-width: 720px) {
    display: none;
  }

  @media (min-height: 780px) {
    margin-top: 100px;
  }
`;

export const OfferMobile = styled('div')`
  margin-top: 90px;
  @media (min-width: 720px) {
    display: none;
  }
`;

export const RequestText = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 40px;
  width: 180px;

  @media (max-width: 390px) {
    width: 170px;
    margin-left: 20px;
    font-size: 14px;
  }
`;

export const RequestCard = styled('div')`
  width: 214px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 45%;
  margin-top: 20px;
  padding: 30px 20px;
  margin-left: 16px;
`;

export const Input = styled('input')`
  all: unset;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 28px;
  width: 100%;

  ::placeholder {
    color: #aaa7a7;
    font-size: 12px;
  }

  &:hover {
    ::placeholder {
      color: #000;
    }
  }

  @media (min-width: 720px) {
    width: 100%;
    margin-bottom: 40px;
    ::placeholder {
      font-size: 20px;
    }
  }
`;

export const RequestButton = styled('button')`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${COLORS.Morskoi};
  box-shadow: 0px 3px 7px 0px rgba(42, 168, 195, 0.49);
  color: white;
  font-family: Inter;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.5s;
  padding: 8px 30px 8px 30px;

  @media (min-width: 720px) {
    width: 265px;
    height: 50px;
    position: absolute;
    bottom: -100px;
    left: 160px;
    font-size: 20px;
  }

  &:hover {
    filter: brightness(110%);
    box-shadow: 0px 9px 21px 0px rgba(42, 168, 195, 0.49);
  }
`;

export const MenuContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 40px 28px;
  background-color: ${COLORS.Morskoi};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  left: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '100%')};
`;

export const MenuLinks = styled('div')`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MenuItem = styled('div')`
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  color: white;
`;

export const PhoneDesktop = styled('div')`
  display: inline;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const SuccessWindow = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 50px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  position: absolute;
  left: 20%;
  top: 40%;
  border-radius: 10px;
  border: 1px solid white;
  color: white;

  @media (min-width: 720px) {
    border: 1px solid ${COLORS.Morskoi};
    color: black;
    width: 300px;
    height: 50px;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    position: absolute;
    left: 40%;
    top: 100px;
  }
`;

export const Form = styled('form')`
  all: unset;
  width: 100%;
  padding-right: 20px;
`
