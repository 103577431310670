export const ukrLangLocal = {
  request: {
    sent: 'Заявку успішно відправлено'
  },
  header: {
    name: 'Олексій',
    surname: "Кожем'яко",
    prof: 'психолог, психотерапевт',
    linkHead: 'Головна',
    linkExp: 'Досвід',
    linkProblems: 'З чим працюю',
    linkServices: 'Послуги та ціни',
    linkReviews: 'Відгуки',
    linkContacts: 'Контакти',
    phone: '+38 067 639 48 55'
  },
  offer: {
    title: 'ПОДБАЙ',
    title2: ' ПРО СЕБЕ!',
    subtitle: 'Перша консультація',
    subtitle2: 'з психологом',
    free: 'БЕЗКОШТОВНО!'
  },
  input: {
    name: "Ім'я",
    phone: 'Телефон +38 050 888 88 88'
  },
  buttons: {
    request: 'Надіслати заявку',
    callMe: 'Передзвоніть мені',
    send: 'Надіслати'
  },
  description: {
    feature1: 'Cертифікований гештальт-терапевт',
    feature2: 'Досвід роботи - понад 10 років',
    feature3: 'Працюю індивідуально, з парами, групами',
    feature4: 'Очно та on-line',
    feature5: 'Українська, Російська',
    mainTitle: 'Олексій Кожемʼяко - практикуючий психолог',
    title1: {
      number: '4000+',
      text: 'годин професійної підготовки'
    },
    title2: {
      number: '10+',
      text: 'років терапевтичного стажу роботи'
    },
    title3: {
      number: '10+',
      text: 'років | досвіду групової терапії'
    },
    title4: {
      number: '100',
      text: 'відсотків | гарантії конфіденційності'
    }
  },
  problems: {
    title: 'Проблеми, які ми можемо | з вами опрацювати',
    subtitle: 'щоб...',
    card1: 'СІМЕЙНІ ВІДНОСИНИ',
    card1Text: 'гуляти, тримаючись за руки',
    card2: 'ЧОЛОВІК І ЖІНКА',
    card2Text: 'регулярний та із задоволенням',
    card3: 'УСПІХ, КАРʼЄРА, ГРОШІ',
    card3Text: 'зберігати гроші у кишенях, а не в голові',
    card4: 'ОСОБИСТЕ ЗРОСТАННЯ',
    card4Text: 'хотіти та могти',
    card5: 'ВІДНОСИНИ З БАТЬКАМИ',
    card5Text: 'знайти баланс між хочу і треба, між активністю та спокоєм',
    card6: 'ПРИЙОМНІ ДІТИ ТА УСИНОВЛЮВАЧІ',
    card6Text: 'всі були щасливі, що знайшли одне одного',
    card7: 'ВИГОРЯННЯ IT-ФАХІВЦІВ',
    card7Text: 'радіти по понеділках',
    card8: 'ГОРЕ, ТРАВМИ І ВТРАТИ',
    card8Text: 'біль став легшим',
    card9: 'КРИЗОВИЙ СУПРОВІД',
    card9Text: 'прийняти те, що найвіть війна - це частина життя'
  },
  reviews: {
    title: 'Відгуки клієнтів'
  },
  services: {
    title: 'Послуги та ціни',
    cards: {
      card1: {
        title: 'ПЕРСОНАЛЬНА КОНСУЛЬТАЦІЯ',
        time: '60 хв.',
        price: '2 500 грн. / 60 у.о.'
      },
      card2: {
        title: 'ТЕРАПІЯ ДЛЯ ПАР',
        title2: 'І СІМЕЙНА',
        time: '1 год. 20 хв.',
        price: '3 000 грн. / 70 у.о.'
      },
      card3: {
        title: 'ГРУПОВА',
        title2: 'ТЕРАПІЯ',
        time: '2 год. 30 хв.',
        price: '500 грн. / 12 у.о.'
      }
    },
    consult: {
      title: 'КРИЗОВЕ КОНСУЛЬТУВАННЯ',
      text1: 'Добровільна оплата, ',
      text2: 'пожертвування / Donation ',
      text3: 'з 24 лютого 2022 р.'
    }
  },
  hotline: {
    title: 'Обговоримо?'
  },
  invite: {
    title: 'ПРОСТО ДОЗВОЛЬТЕ СОБІ СПРОБУВАТИ!',
    subtitle: 'ПЕРША консультація',
    free: 'БЕЗКОШТОВНО!',
    text1: 'Залишіть свої контакти, щоб ми могли',
    text2: 'погодити дату та час консультації',
    text3: 'або зʼвяжіться зі мною',
    text4: 'за телефоном +38 067 639 48 55.',
    text5: 'Залишіть свої контакти, щоб ми могли погодити дату',
    text6: "та час консультації або зв'яжіться зі мною",
    text7: 'за телефоном +38 067 639 48 55.'
  },
  history: {
    title: 'Моя історія',
    text1:
      'Моя терапевтична історія почалася у 16 років, коли я "випадково" потрапив до терапевтичної групи Володимира Голубенка і отримав такий заряд відкриттів, що з того часу психотерапія для мене не тільки добрий помічник і попутник по життю, а й джерело натхнення та особистісного, сімейного та кар\'єрного розвитку.',
    text2:
      "Я пройшов безліч психологічних освітніх програм та тренінгів. Прагнучи розвивати свої hard і soft skills, в 1994 році я захопився психотерапією та коучингом. Успішно застосував свої навички у різних сферах бізнесу та в особистому житті. Я більше 20 років в управлінні та розвитку бізнесів, а моїй сім'ї вже 17 років і є чудова дитина.",
    text3:
      'Я маю 2 вищі освіти: технічну та психологічну. Викладаю у Junior Business Academy та в IT-університеті. Граю на гітарі, виступав в естрадному театрі, маю здобутки у спорті. Цікавлюсь розвитком людського потенціалу.',
    text4:
      'З 2012 року я практикуючий психолог. Про мене кажуть, що я стійкий, надійний, творчий, здатний на тривалі стосунки. Практикую індивідуальну, групову терапію. Близькі мені теми - це теми про відносини, розкриття потенціалу, про кохання, любов до себе, самооцінку.'
  },
  recommendations: {
    title: 'Рекомендації'
  },
  footer: {
    title: 'Контакти',
    contactTel: 'Tel: +38 067 639 48 55 (Viber, Telegram, WhatsApp)',
    skype: 'Skype: kozhemyako_a_v',
    address: 'вул. Мечнікова, 11, каб. 404',
    city: 'м. Дніпро, Україна',
    confidential: 'Надсилаючи заявку, ви даєте згоду на обробку персональних даних',
    confidential2: 'та погоджуєтесь з політикою конфіденційності.',
    policy: 'Політика конфіденційності. Відмова від відповідальності.',
    contacts: 'Залишіть свої контакти, щоб ми могли погодити дату та час зустрічі.'
  },
  modal: {
    text1: 'Дякую!',
    text2: 'Вашу заявку відправлено.',
    text3: 'Я звʼяжусь з Вами найближчим часом!',
    button: 'Повернутися на головну'
  },
  readNext: {
    open: 'Читати далі...',
    close: 'Згорнути'
  },
  links: {
    write: 'Пишіть:',
    telegram: 'https://t.me/Aleks_Kozhemyako',
    whatsapp: 'https://wa.link/y8530s'
  }
};
