export const ruLangLocal = {
  request: {
    sent: 'Заявку отправлено успешно'
  },
  header: {
    name: 'Алексей Кожемяко',
    prof: 'психолог, психотерапевт',
    linkHead: 'Главная',
    linkExp: 'Опыт',
    linkProblems: 'С чем работаю',
    linkServices: 'Услуги и цены',
    linkReviews: 'Отзывы',
    linkContacts: 'Контакты',
    phone: '+38 067 639 48 55'
  },
  offer: {
    title: 'Позаботься',
    title2: 'о себе!',
    subtitle: 'Первая консультация',
    subtitle2: 'с психологом',
    free: 'БЕСПЛАТНО!'
  },
  input: {
    name: 'Имя',
    phone: 'Телефон +38 050 888 88 88'
  },
  buttons: {
    request: 'Отправить заявку',
    callMe: 'Перезвоните мне',
    send: 'Отправить'
  },
  description: {
    feature1: 'Cертифицированный гештальт-терапевт',
    feature2: 'Опыт работы - более 10 лет',
    feature3: 'Работаю индивидуально, с парами, группами',
    feature4: 'Очно и on-line',
    feature5: 'Русский, Украинский',
    mainTitle: 'Алексей Кожемяко - практикующий психолог',
    title1: {
      number: '4000+',
      text: 'часов профессиональной подготовки'
    },
    title2: {
      number: '10+',
      text: 'лет | терапевтического стажа работы'
    },
    title3: {
      number: '10+',
      text: 'лет | опыта групповой терапии'
    },
    title4: {
      number: '100',
      text: 'процентов | гарантия конфиденциальности'
    }
  },
  problems: {
    title: 'Проблемы, которые мы | можем с вами проработать',
    card1: 'СЕМЕЙНЫЕ ОТНОШЕНИЯ',
    card2: 'МУЖЧИНА И ЖЕНЩИНА ',
    card3: 'УСПЕХ, КАРЬЕРА, ДЕНЬГИ',
    card4: 'САМОРАЗВИТИЕ И ЛИЧНОСТНЫЙ РОСТ',
    card5: 'ОТНОШЕНИЯ С РОДИТЕЛЯМИ',
    card6: 'ПРИЕМНЫЕ ДЕТИ И УСЫНОВИТЕЛИ',
    card7: 'ВЫГОРАНИЕ IT-СПЕЦИАЛИСТОВ',
    card8: 'ГОРЕ, ТРАВМЫ И ПОТЕРИ',
    card9: 'КРИЗИСНОЕ СОПРОВОЖДЕНИЕ'
  },
  reviews: {
    title: 'Отзывы клиентов'
  },
  services: {
    title: 'Услуги и цены',
    cards: {
      card1: {
        title: 'Персональная консультация',
        time: '60 мин.',
        price: '2 500 грн. / 60 у.е.'
      },
      card2: {
        title: 'Терапия для пар',
        title2: 'и семейная',
        time: '1 ч 20 мин.',
        price: '3 000 грн. / 70 у.е.'
      },
      card3: {
        title: 'Групповая',
        title2: 'терапия',
        time: '2 ч 30 мин.',
        price: '500 грн. / 12 у.е.'
      }
    },
    consult: {
      title: 'Кризисное консультирование',
      text1: 'Добровольная оплата, ',
      text2: 'пожертвование / Donation',
      text3: 'с 24 февраля 2022 г.'
    }
  },
  hotline: {
    title: 'Обсудим?'
  },
  invite: {
    title: 'Просто разрешите себе попробовать!',
    subtitle: 'ПЕРВАЯ консультация БЕСПЛАТНО!',
    text1: 'Оставьте свои контакты, чтобы мы могли',
    text2: 'согласовать дату и время консультации',
    text3: 'или свяжитесь со мной',
    text4: 'по телефону +38 067 639 48 55.',
    text5: 'Оставьте свои контакты, чтобы мы могли согласовать',
    text6: 'дату и время консультации или свяжитесь со мной',
    text7: 'по телефону +38 067 639 48 55.'
  },
  history: {
    title: 'Моя история',
    text1:
      'Моя терапевтическая история началась в 16 лет, когда я "случайно" попал в терапевтическую группу Владимира Голубенко и получил такой заряд открытий, что с тех пор психотерапия для меня не только добрый помощник и попутчик по жизни, но и источник вдохновения и личностного, семейного и карьерного развития.',
    text2:
      'Я прошел множество психологических образовательных программ и тренингов. Стремясь развивать свои hard и soft skills, в 1994 году я увлекся психотерапией и коучингом. Успешно применил свои навыки в разных сферах бизнеса и в личной жизни. Я более 20 лет в управлении и развитии бизнесов, а моей семье уже 17 лет и есть замечательный ребёнок.',
    text3:
      ' Я имею 2 высших образования: техническое и психологическое. Преподаю в Junior Business Academy и в IT-университете. Играю на гитаре, выступал в эстрадном театре, имею достижения в спорте. Интересуюсь развитием человеческого потенциала.',
    text4:
      'С 2012 года я практикующий психолог. Обо мне говорят, что я устойчивый, надёжный, творческий, способный на длительные отношения. Практикую индивидуальную, групповую терапию. Близкие мне темы - это темы об отношениях, раскрытии потенциала, о любви, любви к себе, самооценке.'
  },
  recommendations: {
    title: 'Рекомендации'
  },
  footer: {
    title: 'Контакты',
    contactTel: 'Tel: +38 067 639 48 55 (Viber, Telegram, WhatsApp)',
    skype: 'Skype: kozhemyako_a_v',
    address: 'ул. Мечникова, 11, каб. 404',
    city: 'г. Днепр, Украина',
    confidential: 'Отправляя заявку, вы даете согласие на обработку персональных данных и',
    confidential2: 'соглашаетесь c политикой конфиденциальности.',
    contacts: 'Оставьте свои контакты, чтобы мы могли согласовать дату и время встречи.',
    policy: 'Политика конфиденциальности. Отказ от ответственности.'
  },
  modal: {
    text1: 'Благодарю!',
    text2: 'Ваша заявка отправлена.',
    text3: 'Я свяжусь с Вами в ближайшее время!',
    button: 'Вернуться на главную'
  },
  readNext: {
    open: 'Читать далее...',
    close: 'Свернуть'
  },
  links: {
    write: 'Пишите:',
    telegram: 'https://t.me/Aleks_Kozhemyako',
    whatsapp: 'https://wa.link/y8530s',
  }
};
