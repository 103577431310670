import { Background, Button, MainContainer, Text } from "./index.styled";

export const Modal = ({ lang, toggleModal }) => {
  const handleModal = () => {
    toggleModal();
    window.scrollTo(0, 0);
  }
  
  return (
    <Background>
      <MainContainer>
        <Text style={{ marginTop: '60px', marginBottom: '24px' }}>{lang.modal.text1}</Text>
        <Text>{lang.modal.text2}</Text>
        <Text>{lang.modal.text3}</Text>
        <Button onClick={handleModal}>{lang.modal.button}</Button>
      </MainContainer>
    </Background>
  );
};
