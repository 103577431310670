import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Element } from 'react-scroll';

import * as theme from '../theme';
import * as Styled from '../app/app.styled';
import '../../style.css';
import { FrontPage } from '../common/components/frontPage/frontPage';
import { YoutubeEmbed } from '../common/components/video/video';
import { Description } from '../common/components/description/description';
import { MyHistory } from '../common/components/myHistory/myHistory';
import { Problems } from '../common/components/problems/problems';
import { Hotline } from '../common/components/hotline/hotline';
import { Review } from '../common/components/reviews/reviews';
import { Services } from '../common/components/services/services';
import { Invite } from '../common/components/invite/invite';
import { Footer } from '../common/components/footer/footer';
import { Recommendations } from '../common/components/recommendations/recommendations';
import { Modal } from '../common/components/modal';

export const MainRouter = ({ toggleModal, handleLanguageChange, language, showModal, ukrLang, ruLang }) => (
  <Router>
    <Switch>
      <Route
        component={() => (
          <ThemeProvider theme={theme}>
            <Styled.GlobalStyles />
            <Element name="frontpage">
              <FrontPage
                toggleModal={toggleModal}
                lang={language}
                onChangeLanguage={handleLanguageChange}
              />
            </Element>
            <YoutubeEmbed />
            <Element name="description">
              <Description lang={language} />
            </Element>
            <Element name="problems">
              <Problems lang={language} />
            </Element>
            <Hotline toggleModal={toggleModal} lang={language} />
            <Element name="reviews">
              <Review lang={language} />
            </Element>
            <Element name="services">
              <Services lang={language} />
            </Element>
            <Element name="invite">
              <Invite toggleModal={toggleModal} lang={language} />
            </Element>
            <Element name="history">
              <MyHistory lang={language} />
            </Element>
            <Recommendations lang={language} />
            <Element name="footer">
              <Footer toggleModal={toggleModal} lang={language} />
            </Element>
            {showModal && (
              <Element name="modal">
                <Modal toggleModal={toggleModal} lang={language} />
              </Element>
            )}
          </ThemeProvider>
        )}
        path="/"
        exact
      />
    </Switch>
  </Router>
);
