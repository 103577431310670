import { useState } from "react";
import { Link } from 'react-scroll';

import { LangSelect, LangSelectMobile } from "../langSelect/langSelect";
import { Shape, Container, Header, HeaderText, HeaderName, HeaderProfess, HeaderPhone, MainText, TopText, BottomText, ThinText, FatText, RequestButton, RequestContainer, RequestCard, RequestText, Input, HeaderPhoneIcon, ShapeContainer, MenuContainer, MenuItem, MenuLinks, HeaderLinks, HeaderLink, Offer, OfferMobile, Background, PhoneDesktop, WhiteTextContainer, WhiteTextFat, WhiteTextThin, ResponsiveBR, SuccessWindow, Form } from "./frontPage.styled";
import { ReactComponent as Phone } from '../../../../assets/image/phone.svg';
import { ReactComponent as PhoneBlue } from '../../../../assets/image/phoneblue.svg';
import { ReactComponent as Burger } from '../../../../assets/image/burger.svg';
import { ReactComponent as Cross } from '../../../../assets/image/cross.svg';
import { sendEmail } from "../../utils/func/sendEmail";

export const FrontPage = ({ toggleModal, lang, onChangeLanguage }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [requestSent, setRequestSent] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  if (requestSent) {
    setTimeout(() => {
      setRequestSent(false);
    }, 5000);
  }

  return (
    <Container>
      <Background />
      <WhiteTextContainer>
        <WhiteTextFat>
          {lang.header.name} {lang.header.surname}
        </WhiteTextFat>
        <WhiteTextThin>{lang.header.prof}</WhiteTextThin>
      </WhiteTextContainer>
      <Shape />
      <Header>
        <HeaderText>
          <HeaderName>
            {lang.header.name} <br /> {lang.header.surname}
          </HeaderName>
          <HeaderProfess>{lang.header.prof}</HeaderProfess>
        </HeaderText>

        <HeaderLinks>
          <Link to="frontpage" smooth={true} duration={500}>
            <HeaderLink>{lang.header.linkHead}</HeaderLink>
          </Link>
          <Link to="description" offset={-50} smooth={true} duration={500}>
            <HeaderLink>{lang.header.linkExp}</HeaderLink>
          </Link>
          <Link to="problems" offset={-100} smooth={true} duration={500}>
            <HeaderLink>{lang.header.linkProblems}</HeaderLink>
          </Link>
          <Link to="services" offset={-20} smooth={true} duration={500}>
            <HeaderLink>{lang.header.linkServices}</HeaderLink>
          </Link>
          <Link to="reviews" offset={-100} smooth={true} duration={500}>
            <HeaderLink>{lang.header.linkReviews}</HeaderLink>
          </Link>
          <Link to="footer" offset={-100} smooth={true} duration={500}>
            <HeaderLink>{lang.header.linkContacts}</HeaderLink>
          </Link>
        </HeaderLinks>
        <LangSelect selectedLanguage={lang} onChangeLanguage={onChangeLanguage} />
        <HeaderPhone href="tel:+380676394855">
          <Phone />{' '}
          <PhoneDesktop>
            <PhoneBlue />{' '}
          </PhoneDesktop>
          &nbsp;{lang.header.phone}
        </HeaderPhone>
        <Burger onClick={handleMenuClick} />
        <MenuContainer isMenuOpen={isMenuOpen}>
          <MenuLinks>
            <Link
              onClick={handleMenuClick}
              to="frontpage"
              smooth={true}
              duration={500}
              offset={-100}
            >
              <MenuItem>{lang.header.linkHead}</MenuItem>
            </Link>
            <Link
              onClick={handleMenuClick}
              to="description"
              smooth={true}
              duration={500}
              offset={-100}
            >
              <MenuItem>{lang.header.linkExp}</MenuItem>
            </Link>
            <Link
              onClick={handleMenuClick}
              to="problems"
              smooth={true}
              duration={500}
              offset={-120}
            >
              <MenuItem>{lang.header.linkProblems}</MenuItem>
            </Link>
            <Link
              onClick={handleMenuClick}
              to="services"
              smooth={true}
              duration={500}
              offset={-120}
            >
              <MenuItem>{lang.header.linkServices}</MenuItem>
            </Link>
            <Link onClick={handleMenuClick} to="reviews" smooth={true} duration={500} offset={-120}>
              <MenuItem>{lang.header.linkReviews}</MenuItem>
            </Link>
            <Link onClick={handleMenuClick} to="footer" smooth={true} duration={500} offset={-50}>
              <MenuItem>{lang.header.linkContacts}</MenuItem>
            </Link>
            <LangSelectMobile selectedLanguage={lang} onChangeLanguage={onChangeLanguage} />
          </MenuLinks>
          <Cross style={{ width: 20, height: 20 }} onClick={handleMenuClick} />
        </MenuContainer>
      </Header>

      <OfferMobile>
        <MainText>
          <TopText>
            {lang.offer.title} <ResponsiveBR /> {lang.offer.title2}
          </TopText>

          <BottomText>
            <ThinText>
              {lang.offer.subtitle} <ResponsiveBR /> {lang.offer.subtitle2}
            </ThinText>

            <FatText>{lang.offer.free}</FatText>
          </BottomText>
        </MainText>
      </OfferMobile>
      <Offer>
        <MainText>
          <TopText>{lang.offer.title}</TopText>
          <TopText>{lang.offer.title2}</TopText>

          <BottomText>
            <ThinText>
              {lang.offer.subtitle} {lang.offer.subtitle2}
            </ThinText>

            <FatText>{lang.offer.free}</FatText>
          </BottomText>
        </MainText>
        <form onSubmit={(e) => sendEmail(e, phone, name, setName, setPhone, toggleModal)}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder={lang.input.name}
          />
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            placeholder={lang.input.phone}
          />
          <RequestButton>{lang.buttons.request}</RequestButton>
        </form>
      </Offer>
      <RequestContainer>
        <RequestText>
          <strong>
            <span style={{ textTransform: 'uppercase' }}>{lang.header.name}</span>{' '}
            <span style={{ textTransform: 'uppercase' }}>{lang.header.surname}</span>
          </strong>
          <br /> {lang.header.prof}
        </RequestText>
        <RequestCard>
          <Form onSubmit={(e) => sendEmail(e, phone, name, setName, setPhone, toggleModal)}>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder={lang.input.name}
            />
            <Input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              placeholder={lang.input.phone}
            />
            <RequestButton>{lang.buttons.request}</RequestButton>
          </Form>
        </RequestCard>
      </RequestContainer>
    </Container>
  );
};