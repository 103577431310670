import styled, { css } from "styled-components";
import { COLORS } from "../../../theme";

export const DescriptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (min-width: 720px) {
    margin: auto;
    max-width: 1420px;
    flex-direction: column-reverse;
    padding: 0 70px;
  }

  .swiper {
    height: 400px !important;
    width: 550px !important;
    position: relative !important;
    right: 0 !important;
  }
`;

export const TitleMobile = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  padding: 36px 50px 0;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const TitleDesk = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-weight: 800;
  line-height: 28px;
  padding: 60px 48px 0;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const DescriptionText = styled('div')`
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 122%;
  width: 100%;
  margin-top: 20px;
  padding: 0 0 48px 36px;

  @media (min-width: 720px) {
    font-size: 24px;
  }
`;

export const DescriptionLine = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  margin-left: 8px;
`;

export const Icon = styled('img')`
  width: 32px;
  height: 32px;
`;

export const Text = styled('div')`
  width: 80%;
`;

export const ImageContainer = styled('div')`
  display: flex;
  width: 383px;
  height: 100%;
  background-color: ${COLORS.Morskoi};
  padding: 32px 52px;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const Diploma = styled('img')`
  width: 383.726px;
  height: 278px;
  margin: 52px auto;
  object-fit: contain;

  @media (max-width: 720px) {
    position: absolute;
    left: 12.5%;
    top: -20px;
    width: 310px;
    height: 224px;
    margin: 52px auto;
  }
`;

export const StatisticsDesktop = styled('div')`
  width: 100%;
  padding: 48px;

  @media (min-width: 720px) {
    display: flex;
    gap: 100px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const StatisticsMobile = styled('div')`
  width: 100%;
  padding: 48px;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const Stat = styled('div')`
  @media (min-width: 720px) {
    width: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;


export const BigText = styled('div')`
  color: ${COLORS.Morskoi};
  text-align: center;
  font-family: Lato;
  font-size: 40px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 3.4px;
`;

export const SmallText = styled('div')`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;

  @media (min-width: 720px) {
    font-size: 18px;
  }
`;

export const SwiperContainerMobile = styled('div')`
  display: flex;
  width: 100%;
  height: 318px;
  background-color: ${COLORS.Morskoi};

  @media (min-width: 720px) {
    display: none;
  }

  .swiper {
    width: 500px;
    height: 318px !important;

    .swiper-slide {
      padding-bottom: 10px !important;
    }

    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }
  }
`; 
export const SwiperContainerDesktop = styled('div')`
  @media (max-width: 720px) {
    display: none;
  }

  .swiper {
    width: 500px;
    height: 500px;
  }
`; 

export const StyledPagination = styled.div`
  .swiper-pagination {
    @media (max-width: 720px) {
      bottom: 7%;
      left: 64%;
    }
    position: absolute;
    bottom: 10px;
    left: 70.5%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    z-index: 10;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: #c4c4c4;
      opacity: 1;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &.swiper-pagination-bullet-active {
        background-color: #fff;
      }
    }
  }

  @media (min-width: 720px) {
    .swiper-pagination {
      left: 73.5%;
      bottom: 0;
      .swiper-pagination-bullet {
        background-color: #c4c4c4;

        &.swiper-pagination-bullet-active {
          background-color: #2aa8c3;
        }
      }
    }
  }
`;

export const DescriptionContainerSwiper = styled.div`
  display: flex;
  align-items: center;
`