import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation } from 'swiper/modules';
import { cards } from './cards.js';
import {
  ReviewContainer,
  ReviewTitle,
  Comment,
  ReviewCardContainer,
  ReviewCardOuter,
  ReviewCardInner,
  CommentTitle,
  ReviewCardContainerDesktop,
  ReviewCardOuterDesktop,
  SizeContainer,
  ColorContainer,
  NavigationContainer
} from './reviews.styled';

export const Review = ({ lang }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperParams = {
    style: {
      '--swiper-navigation-size': '50px'
    },
    modules: [Navigation],
    navigation: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 3,
    spaceBetween: 50,
    centeredSlides: true,
    loop: true,
    onSlideChange: (swiper) => {
      const newIndex = swiper.activeIndex;
      setActiveIndex(newIndex);
    }
  };

  return (
    <ReviewContainer>
      <ReviewTitle>{lang.reviews.title}</ReviewTitle>
      <ColorContainer>
        <ReviewCardContainer>
          <Swiper
            modules={[Navigation]}
            navigation={true}
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            className="swiper"
          >
            {cards.map((card) => (
              <SwiperSlide key={card.id}>
                <ReviewCardOuter>
                  <ReviewCardInner>
                    <Comment>
                      <CommentTitle>{card.title}</CommentTitle>
                      {card.content}
                    </Comment>
                  </ReviewCardInner>
                </ReviewCardOuter>
              </SwiperSlide>
            ))}
          </Swiper>
        </ReviewCardContainer>
      </ColorContainer>
      <ColorContainer>
        <ReviewCardContainerDesktop>
          <Swiper {...swiperParams}>
            {cards.map((card, index) => (
              <SwiperSlide style={{ width: '222px' }} key={card.id}>
                <ReviewCardOuterDesktop className="CardOuter" isActive={index === activeIndex}>
                  <ReviewCardInner>
                    <Comment>
                      <CommentTitle>{card.title}</CommentTitle>
                      {card.content}
                    </Comment>
                  </ReviewCardInner>
                </ReviewCardOuterDesktop>
              </SwiperSlide>
            ))}
            <NavigationContainer>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
            </NavigationContainer>
          </Swiper>
        </ReviewCardContainerDesktop>
      </ColorContainer>
    </ReviewContainer>
  );
};
