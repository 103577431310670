import styled from "styled-components";

export const MyHistoryContainer = styled('div')`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1420px;
  margin: 50px auto 80px;

  @media (min-width: 720px) {
    margin: 200px auto 120px;
    padding: 0 70px;
  }
`;

export const MyHistoryTitle = styled('div')`
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 136%;
  text-align: center;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const DesktopTitle = styled('div')`
  display: none;

  @media (min-width: 1102px) {
    margin-bottom: 65px;
    align-self: start;
    display: flex;
    font-family: 'Inter';
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 136%;
  }

  @media (min-width: 720px) and (max-width: 1102px) {
    margin-bottom: 30px;
    align-self: start;
    display: flex;
    font-family: 'Inter';
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 136%;
  }
`;

export const SecondContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  padding: 5px;
  margin-bottom: 40px;

  @media (min-width: 720px and max-width: 1320px) {
    margin-bottom: 60px;
  }
`;

export const MyHistoryPhoto = styled('img')`
  border-radius: 15px;
  height: 200px;
  width: 380px;
  object-fit: cover;
  box-shadow: 2px 2px 10px 2px #2aa7c37c;

  @media (min-width: 720px) and (max-width: 1320px) {
    width: 360px;
    height: 360px;
  }

  @media screen and (min-width: 1320px) {
    align-self: start;
    width: 400px;
    height: 400px;
  }
`;

export const TextContainer = styled('div')`
  padding: 15px;

  @media (min-width: 720px) {
    height: 400px;
    padding: 0;
    align-self: end;
  }
`;

export const TitleContainer = styled('div')`
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  
  @media (min-width: 720px) {
    flex-direction: row;
    gap: 45px;
  }
`;

export const MyHistoryText = styled('div')`
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 

  @media (min-width: 720px) and (max-width: 1320px) {
    font-size: 20px;
    line-height: 45px;
  }

  @media (min-width: 1320px) {
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
  }
`;

export const DesktopTextContainer = styled('div')`
  display: none;

  @media (min-width: 720px) {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
    gap: 60px;
  }
`;

export const Anchor = styled('a')`
  color: #2AA8C3;
  cursor: pointer;

  @media (min-width: 720px) {
    display: none;
  }
`;

export const GalleryContainer = styled('div')`
  align-self: center;
  display: flex;
  max-width: 400px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  margin: 10px;

  @media (min-width: 1120px) {
    flex-direction: row;
    max-width: 1120px;
    align-self: center;
  }
`;

export const Photo_1 = styled('img')`
  height: 82px;
  width: 82px;
  border-radius: 15px 0px 0px 0px;
  object-fit: cover;
  align-self: end;
  filter: grayscale(100%);
  order: 0;

  @media (min-width: 980px) {
    width: 150px;
    height: 150px;
  }
`;

export const Photo_2 = styled('img')`
  height: 136px;
  width: 204px;
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
  align-self: end;
  filter: grayscale(0%);
  order: 1;

  @media (min-width: 980px) {
    height: 338px;
    width: 450px;
  }
`;

export const Photo_3 = styled('img')`
  height: 126px;
  width: 190px;
  border-radius: 0px 0px 0px 20px;
  object-fit: cover;
  filter: grayscale(100%);
  order: 2;

  @media (min-width: 720px) {
    border-radius: 0px 20px 0px 0px;
    align-self: end;
    order: 3;
    width: 240px;
    height: 160px;
  }
`;

export const Photo_4 = styled('img')`
  height: 126px;
  width: 96px;
  border-radius: 0px 0px 20px 0px;
  object-fit: cover;
  filter: grayscale(100%);
  order: 3;

  @media (min-width: 720px) {
    border-radius: 0px 0px 0px 20px;
    align-self: start;
    order: 5;
    width: 210px;
    height: 300px;
  }
`;

export const Photo_5 = styled('img')`
  height: 150px;
  width: 100px;
  border-radius: 0px 0px 20px 20px;
  object-fit: cover;
  align-self: start;
  filter: grayscale(100%);
  order: 5;

  @media (min-width: 720px) {
    order: 6;
    height: 338px;
    width: 450px;
  }
`;

export const Photo_6 = styled('img')`
  height: 120px; 
  width: 90px;
  border-radius: 0px 0px 20px 0px;
  object-fit: cover;
  align-self: start;
  filter: grayscale(100%);
  order: 6;

  @media (min-width: 720px) {
    border-radius: 0px 20px 0px 0px;
    align-self: end;
    order: 2;
    width: 210px;
    height: 300px;
  }
`;

export const Photo_7 = styled('img')`
  height: 80px;
  width: 80px;
  border-radius: 0px 0px 16px 0px;
  object-fit: cover;
  align-self: start;
  filter: grayscale(100%);
  order: 6;

  @media (min-width: 720px) {
    width: 150px;
    height: 150px;
    order: 6;
  }
`;

export const Photo_8 = styled('img')`
  display: none;

  @media (min-width: 1120px) {
    border-radius: 0px 0px 0px 16px;
    align-self: start;
    width: 240px;
    height: 160px;
    filter: grayscale(100%);
    display: flex;
    order: 4;
  }
`;

export const GalleryShadow = styled('div')`
  display: none;
  
  @media (min-width: 1320px) {
    display: flex;
    position: absolute;
    width: 1110px;
    height: 734px;
    flex-shrink: 0;
    border-radius: 1110px;
    background: rgba(77, 200, 227, 0.3);
    filter: blur(27px); 
  }
`;