import { useState } from "react";

import { sendEmail } from "../../utils/func/sendEmail";
import { DesktopBr, MobileBr } from "../services/services.styled";
import { Form, SuccessWindow, InviteContainer, InviteTitle, Input, RequestButton, FatText, ThinText, TextContainer, ContactContainer, ContactText, RequestCard, TextContainerDesktop, InviteContainerDesktop, ContactLine } from "./invite.styled";


export const Invite = ({ lang, toggleModal }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [requestSent, setRequestSent] = useState(false);

  if (requestSent) {
    setTimeout(() => {
      setRequestSent(false);
    }, 3000);
  }

  return (
    <>
      <InviteContainer>
        <TextContainer>
          <InviteTitle>{lang.invite.title}</InviteTitle>
          <TextContainerDesktop>
            <ThinText> {lang.invite.subtitle}&nbsp;</ThinText>
            <FatText> {lang.invite.free}</FatText>
          </TextContainerDesktop>
        </TextContainer>
        <ContactContainer>
          <ContactText>
            {lang.invite.text1}
            <MobileBr />
            {lang.invite.text2}
            <MobileBr />
            {lang.invite.text3}
            <MobileBr />
            {lang.invite.text4}
          </ContactText>
        </ContactContainer>
        <Form onSubmit={(e) => sendEmail(e, phone, name, setName, setPhone, toggleModal)}>
          <ContactContainer style={{ paddingTop: '0' }}>
            <ContactText>{lang.invite.text}</ContactText>
            <RequestCard>
              <Input
                required
                placeholder={lang.input.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Input
                required
                placeholder={lang.input.phone}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </RequestCard>
            <RequestButton>{lang.buttons.send}</RequestButton>
          </ContactContainer>
        </Form>
      </InviteContainer>
      <InviteContainerDesktop>
        <TextContainer style={{ paddingBottom: '0px' }}>
          <InviteTitle>{lang.invite.title}</InviteTitle>
          <TextContainerDesktop>
            <ThinText> {lang.invite.subtitle}&nbsp;</ThinText>
            <FatText>{lang.invite.free}</FatText>
          </TextContainerDesktop>
        </TextContainer>
        <Form onSubmit={(e) => sendEmail(e, phone, name, setName, setPhone, toggleModal)}>
          <ContactContainer>
            <ContactText>
              <ContactLine>{lang.invite.text5}</ContactLine>
              <ContactLine>{lang.invite.text6}</ContactLine>
              <ContactLine>{lang.invite.text7}</ContactLine>
            </ContactText>
            <RequestCard>
              <Input
                required
                placeholder={lang.input.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Input
                required
                placeholder={lang.input.phone}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </RequestCard>
          </ContactContainer>
          <RequestButton>{lang.buttons.send}</RequestButton>
        </Form>
      </InviteContainerDesktop>
    </>
  );
}
