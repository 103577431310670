import { RecommendationsContainer, RecommendationsTitle, Card, CardDesktop, CardContainer, CardPhoto, CardText, CardTitle, SwiperContainer, SwiperMediumContainer, NavigationContainer } from "./recommendations.styles";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { data } from "./slider";
import { Navigation } from 'swiper/modules';

export const Recommendations = ({ lang }) => {
  const swiperParams = {
    navigation: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    modules: [Navigation],
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    className: "swiper"
  }
  return (
    <>
      <RecommendationsContainer>
        <RecommendationsTitle>{lang.recommendations.title}</RecommendationsTitle>
        <SwiperContainer>
          <Swiper {...swiperParams}>
            {data.map((dates) => (
              <SwiperSlide key={dates.id}>
                <Card>
                  <CardContainer>
                    <CardPhoto backgroundPosition={dates.backgroundPosition} backgroundSize={dates.backgroundSize} src={dates.picture} />
                    <CardDesktop src={dates.desktopRecommendation} />
                    <CardTitle>{dates.title}</CardTitle>
                    <CardText style={{fontFamily:"Lato", fontSize:"12px"}}>
                      {dates.firstContent.split('*').map((text, index) => {
                        if (index % 2 === 0) {
                          return <span key={index}>{text}</span>;
                        } else {
                          return (
                            <strong style={{ fontFamily: 'LatoOriginal', fontWeight:'700' }} key={index}>
                              {text}
                            </strong>
                          );
                        }
                      })}
                    </CardText>
                    <CardText>
                      {' '}
                      {dates.secondContent.split('*').map((text, index) => {
                        if (index % 2 === 0) {
                          return <span key={index}>{text}</span>;
                        } else {
                          return <strong key={index}>{text}</strong>;
                        }
                      })}
                    </CardText>
                    <CardText>
                      {dates.thirdContent.split('*').map((text, index) => {
                        if (index % 2 === 0) {
                          return <span key={index}>{text}</span>;
                        } else {
                          return <strong key={index}>{text}</strong>;
                        }
                      })}
                    </CardText>
                  </CardContainer>
                </Card>
              </SwiperSlide>
            ))}
            <NavigationContainer>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
            </NavigationContainer>
          </Swiper>
        </SwiperContainer>
      </RecommendationsContainer>

      <SwiperMediumContainer>
        <Swiper
          style={{
            '--swiper-navigation-size': '70px',
            '--swiper-navigation-sides-offset': '15px',
            '--swiper-navigation-top-offset': '40%'
          }}
          navigation={true}
          modules={[Navigation]}
          slidesPerView={2}
          centeredSlides={true}
          loop={true}
          className="swiper"
        >
          {data.map((dates) => (
            <SwiperSlide key={dates.id}>
              <Card>
                <CardContainer>
                  <CardPhoto src={dates.picture} />
                  <CardTitle>{dates.title}</CardTitle>
                  <CardText>{dates.firstContent}</CardText>
                  <CardText>{dates.secondContent}</CardText>
                  <CardText>{dates.thirdContent}</CardText>
                </CardContainer>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperMediumContainer>
    </>
  );
};